import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import moment from 'moment-timezone';
import { Formik, Form, Field } from 'formik';
import { Grid, Paper, Stepper, Step, StepLabel, TextField, Select, MenuItem, makeStyles, withStyles, Menu } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { getChurches } from '../../../../actions/churches/churchActions';
import States from '../../../common/data/states';
import Countries from '../../../common/data/countries';
import Months from '../../../common/data/months';
import * as Yup from 'yup';
import MaskedPhoneNumberInput from '../../../common/form_helpers/maskedPhoneNumberInput';

const MyInput = ({ field, form, ...rest }) => {
    //console.log('form', field.name);
    return (
        <div>
            <TextField style={{ whiteSpace: 'normal !important' }} fullWidth={true} variant="outlined" {...field} {...rest} />
            {form.errors[field.name]
                && form.touched[field.name]
                && <div style={{ color: 'red' }}><p>{form.errors[field.name]}</p></div>}
            <br />
        </div>
    )
}

const MySelect = ({ field, form, ...rest }) => {
    //console.log('form', field.name);
    return (
        <div>
            <Select style={{ whiteSpace: 'normal !important' }} fullWidth={true} variant="outlined" {...field} {...rest} />
            {form.errors[field.name]
                && form.touched[field.name]
                && <div style={{ color: 'red' }}><p>{form.errors[field.name]}</p></div>}
            <br />
        </div>
    )
}

const useStyles = {
    root: {
        whiteSpace: 'normal !important',
        "& .MuiSelect-selectMenu": {
            whiteSpace: 'normal'
        }
    }
};

class RegistrationForm extends Component {

    static propTypes = {
        submitFunc: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            churches: [],
            timezone: '',
            formStep: 0
        };
    }

    getChurches = () => {
        getChurches().then((response) => {
            this.setState({ churches: response.data });
        });
    }

    setTimezone = () => {
        const autoDetectedTimezone = moment.tz.guess();

        this.setState({ timezone: autoDetectedTimezone }, () => console.log(this.state));
    }

    componentDidMount() {
        this.getChurches();
        this.setTimezone();
    }

    renderUserForm = () => {
        const { classes } = this.props;
        return (
            <div>
                <h5>Create Account</h5><br />
                <Grid container spacing={2} justify="left">
                    <Grid item xs={12} md={12}>
                        <Field component={MyInput} name='email' label="Email Address" required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field component={MyInput} name='password' label="Password" type='password' required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Field component={MyInput} name='password_confirmation' label="Confirm Password" type='password' required />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Field component={MyInput} select className={classes.root} label='Church' name='church_id' required>
                            <MenuItem value=''>Select Church</MenuItem>
                            {
                                this.state.churches.map((church) => {
                                    return (
                                        <MenuItem key={church.id} value={church.id}>{church.name} - {church.city}, {church.state ? church.state + ', ' : ''} {church.country}</MenuItem>
                                    )
                                })
                            }}
                        </Field>
                    </Grid>
                </Grid>
            </div >
        )
    }

    handlePhoneChange = (e, setFieldValue, fieldName) => {
        const cleanedNumber = new String(e.target.value).replace(/\D/g, '');
        let formattedNumber = '';
        if (cleanedNumber.length > 6) {
            formattedNumber = `(${cleanedNumber.substr(0, 3)})-${cleanedNumber.substr(3, 3)}-${cleanedNumber.substr(6, 4)}`
        } else if (cleanedNumber.length > 3) {
            formattedNumber = `(${cleanedNumber.substr(0, 3)})-${cleanedNumber.substr(3, 3)}`;
        } else if (cleanedNumber.length > 0) {
            formattedNumber = `(${cleanedNumber.substr(0, 3)}`;
        } else {
            formattedNumber = '';
        }
        setFieldValue(fieldName, formattedNumber);
    }

    renderPersonForm = (values, setFieldTouched, setFieldValue) => {
        const { classes } = this.props;
        return (
            <div>
                <h5>Personal Information</h5><br />
                <Grid container spacing={2}>

                    <Grid item xs={12} md={4}>
                        <Field component={MyInput} label='First Name' name='first_name' required />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Field component={MyInput} label='Middle Name' name='middle_name' />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Field component={MyInput} label='Last Name' name='last_name' required />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Field component={MyInput} onChange={(e) => this.handlePhoneChange(e, setFieldValue, 'primary_phone')} label='Primary Phone Number' name='primary_phone' type="text" required />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Field component={MyInput} onChange={(e) => this.handlePhoneChange(e, setFieldValue, 'secondary_phone')} label='Secondary Phone Number' name='secondary_phone' />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Field
                            name={`birthdate`}
                            component={DatePicker}
                            label="Birthdate"
                            placeholder="Birthdate (YYYY-MM-DD)"
                            inputVariant="outlined"
                            format="yyyy-MM-DD"
                            value={values && values.birthdate ? values.birthdate : null}
                            validationFieldParent="minorStudents"
                            validationField="birthdate"
                            fullWidth
                            autoOk
                            onChange={(date) => { setFieldTouched(`birthdate`, true); setFieldValue(`birthdate`, `${date.format('yyyy-MM-DD')}`) }}
                            required
                        />
                    </Grid>

                </Grid>
            </div>
        )
    }

    renderChurchAndAddressForm = () => {
        const { classes } = this.props;
        console.log("CLASSES", classes);
        return (
            <div>
                <h5>Address and Church Information</h5><br />
                <Grid container spacing={2}>

                    <Grid item xs={12} md={12}>
                        <Field component={MyInput} label='Street' name='street' required />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Field component={MyInput} label='City' name='city' required />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Field component={MyInput} label='State' name='state' select>
                            <MenuItem value=''>Select State</MenuItem>
                            {
                                Object.keys(States).map((state_abbr, index) => {
                                    return (
                                        <MenuItem key={index} value={state_abbr}>{States[state_abbr]}</MenuItem>
                                    )
                                })
                            }
                        </Field>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Field component={MyInput} label='Zip Code' name='zip' />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Field style={{ whiteSpace: 'normal !important' }} component={MyInput} label='Country' name='country' select required>
                            <MenuItem style={{ whiteSpace: 'normal !important' }} value=''>Select Country</MenuItem>
                            {
                                Countries.map((country, index) => {
                                    return (
                                        <MenuItem style={{ whiteSpace: 'normal !important' }} key={index} value={country}>{country}</MenuItem>
                                    );
                                })
                            }
                        </Field>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Field component={MyInput} style={{ whiteSpeace: 'normal' }} className={classes.root} select label='Church' name='church_id' required>
                            <option value=''>Select Church</option>
                            {
                                this.state.churches.map((church) => {
                                    return (
                                        <option styles={{ whiteSpace: 'normal' }} key={church.id} value={church.id}>{church.name} - {church.city}, {church.state ? church.state + ', ' : ''} {church.country}</option>
                                    )
                                })
                            }}
                        </Field>
                    </Grid>

                </Grid>
            </div>
        )
    }

    stepForward = () => {
        const { formStep } = this.state;
        this.setState({
            formStep: formStep + 1
        });
    }

    stepBackward = () => {
        const { formStep } = this.state;

        this.setState({
            formStep: formStep - 1
        });
    }

    areCurrentStepFieldsValid = (errors, touched, formFieldComponents, validateField) => {
        let valid = true;

        console.log("ERRORS::::::::::; ", errors);
        console.log("formFieldComponents::::::::::: ", formFieldComponents);
        console.log("TOUCHED::::::::::::::: ", touched);

        const fields = this.returnFieldNamesFromComponentTree(formFieldComponents);
        console.log("RETURN OF FUNCTION:::::::: ", fields);
        fields.forEach((field) => {
            if (field.required && !touched.hasOwnProperty(field.name)) {
                valid = false;
            }

            if (errors.hasOwnProperty(field.name)) {
                valid = false;
            }
        });

        return valid;
    }

    returnFieldNamesFromComponentTree = (formFieldComponents) => this.returnFieldNamesFromComponentTreeRecursiveCall(formFieldComponents, []);
    returnFieldNamesFromComponentTreeRecursiveCall = (formFieldComponents, arrayToReturn) => {
        const _this = this;

        if (formFieldComponents.props !== undefined && formFieldComponents.props.children !== undefined && Array.isArray(formFieldComponents.props.children)) {
            formFieldComponents.props.children.forEach((formField) => {
                _this.returnFieldNamesFromComponentTreeRecursiveCall(formField, arrayToReturn);
            });
        } else if (formFieldComponents.props !== undefined && formFieldComponents.props.children !== undefined && !Array.isArray(formFieldComponents.props.children)
            && formFieldComponents.props.children.props !== undefined && formFieldComponents.props.children.props.name !== undefined) {
            console.log(formFieldComponents.props.children.props.name);
            arrayToReturn.push({ name: formFieldComponents.props.children.props.name, required: formFieldComponents.props.children.props.required });
        }

        return arrayToReturn;
    }

    render() {
        const formStepsInfo = [
            {
                label: 'User Information',
                formRenderFunc: (values, setFieldTouched, setFieldvalue) => this.renderUserForm(),

            },
            {
                label: 'Person Information',
                formRenderFunc: (values, setFieldTouched, setFieldValue) => this.renderPersonForm(values, setFieldTouched, setFieldValue)
            },
            {
                label: 'Address & Church',
                formRenderFunc: (values, setFieldTouched, setFieldValue) => this.renderChurchAndAddressForm()
            }
        ];

        const { formStep } = this.state;

        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .required()
                .email('Email is not valid. (Ex: emad@gmail.com)'),
            password: Yup.string().required().min(8, 'Password must be at least 8 characters.').max(20, 'Password cannot be more than 20 characters.'),
            password_confirmation: Yup.string().required().oneOf([Yup.ref('password')], "Passwords don't match."),

            birthdate: Yup.string()
                .required()
                .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in YYYY-MM-DD format.'),

            primary_phone: Yup.string()
                .required('Primary Phone is required.')
                .matches(/^\(\d{3}\)-\d{3}-\d{4}$/, 'Phone Number must be in (555)-555-5555 format.'),

            secondary_phone: Yup.string()
                .matches(/^\(\d{3}\)-\d{3}-\d{4}$/, 'Phone Number must be in (555)-555-5555 format.'),

            zip: Yup.string()
                .matches(/^\d{5}$/, 'Zip Code must be 5 digits')
        });

        const { timezone } = this.state;
        console.log(this.state.timezone);
        return (
            <>
                <Stepper activeStep={formStep} alternativeLabel>
                    {formStepsInfo.map((step, index) => {
                        return (
                            <Step key={index}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper><br />
                <Grid container>
                    <Grid item md={2} />
                    <Grid item md={8}>
                        <Paper style={{ "padding": "40px" }} elevation={2}>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                    password_confirmation: '',
                                    first_name: '',
                                    last_name: '',
                                    middle_name: '',
                                    church_id: '',
                                    timezone: timezone,
                                    primary_phone: '',
                                    secondary_phone: '',
                                    birthdate: '',
                                    city: '',
                                    street: '',
                                    state: '',
                                    zip: '',
                                    country: ''
                                }}

                                validationSchema={validationSchema}

                                enableReinitialize={true}

                                onSubmit={(values, actions) => {
                                    window.scrollTo(0, 0);
                                    console.log('onsubmit', values);
                                    this.props.submitFunc(values);
                                }}

                            >
                                {({ values, errors, touched, setFieldTouched, setFieldValue }) => (
                                    <Form
                                        onChange={(e) => {
                                            setFieldTouched(e.target.name, true);
                                        }}
                                        onClick={(e) => {
                                            // This makes sure the Select gets touched since onChange doesn't work for select
                                            if (e.target !== null) {
                                                setFieldTouched(e.target.name, true);
                                            }
                                        }}>
                                        {formStepsInfo[formStep].formRenderFunc(values, setFieldTouched, setFieldValue)}
                                        <br /><br />
                                        <Grid container>
                                            <Grid item md={2}>
                                                <Button color='primary' hidden={formStep <= 0} onClick={() => this.stepBackward()}>Back</Button>
                                            </Grid>
                                            <Grid item md={8} />
                                            <Grid item md={2}>
                                                <Button color='primary'
                                                    disabled={!this.areCurrentStepFieldsValid(errors, touched, formStepsInfo[formStep].formRenderFunc())}
                                                    hidden={formStep >= formStepsInfo.length - 1}
                                                    onClick={() => {
                                                        this.stepForward()
                                                    }}
                                                > Next</Button>
                                                <Button color='primary' disabled={!this.areCurrentStepFieldsValid(errors, touched, formStepsInfo[formStep].formRenderFunc())} hidden={formStep < formStepsInfo.length - 1}>Submit</Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Paper>
                    </Grid>
                    <Grid item md={2} />
                </Grid>
                <br /> <br />
            </>
        )
    }
}

export default withStyles(useStyles)(RegistrationForm);