import { GET_CHECKUPS_COUNT, GET_PENDING_USERS_COUNT } from '../../actions/notifications/notificationActions';

const initialState = {
    pending_users_count: 0,
    checkups_count: 0
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case `${GET_CHECKUPS_COUNT}_FULFILLED`: {
            return {
                ...state,
                checkups_count: action.payload.data.count
            };
        }

        case `${GET_PENDING_USERS_COUNT}_FULFILLED`: {
            return {
                ...state,
                pending_users_count: action.payload.data.count
            }
        }
        default:
            return state;
    }
};