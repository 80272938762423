import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
class AddChurchClassForm extends Component {
    static propTypes = {
        afterSubmit: PropTypes.func,
        addClass: PropTypes.func,
        church_id: PropTypes.string
    };

    constructor(props) {
        super(props);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;
        const params = {...this.state, church_id: this.props.church_id };
        this.props.addClass(params).then(function(response){
            _this.props.afterSubmit();
        });
    }
    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup row>
                    <Label md={2}>Name*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='name' type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Description*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='description' type='text' required /> </Col>
                </FormGroup>
                <Row>
                    <Col md='10' />
                    <Col md='2'>
                        <Button color='primary'>Submit</Button>
                    </Col>
                </Row>
            </Form>
        )
    }
}

const mapDispatchToProps = (state) => {
    return {
        church_id: state.auth.church_id
    };
}

export default connect(mapDispatchToProps)(AddChurchClassForm);