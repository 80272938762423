import axios from 'axios';
import { getUnfulfilledCheckupCount } from '../checkups/checkupActions';
import { fetchPendingUsersCount } from '../users/userActions';
const API_URL = process.env.REACT_APP_API_URL;

export const GET_CHECKUPS_COUNT = 'GET_CHECKUPS_COUNT';
export const GET_PENDING_USERS_COUNT = 'GET_PENDING_USERS_COUNT';

export function getAllNotifications() {
    //getCheckupsCount();
}

export function getCheckupsCount() {
    return {
        type: GET_CHECKUPS_COUNT,
        payload: getUnfulfilledCheckupCount()
    };
}

export function getPendingUsersCount() {
    return {
        type: GET_PENDING_USERS_COUNT,
        payload: fetchPendingUsersCount()
    }
}