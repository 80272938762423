import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export function getCheckups() {
    return axios.get(`${API_URL}/checkups`);
}

export function checkupAbsentee(checkup_id) {
    return axios.post(`${API_URL}/checkups/${checkup_id}/checkup`);
}

export function getUnfulfilledCheckupCount() {
    return axios.get(`${API_URL}/checkups/count`);
}