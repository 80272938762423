import React, { Component } from 'react';
import PageHeader from '../../pageHeader';
import { getCheckups, checkupAbsentee } from '../../../actions/checkups/checkupActions';
import { createInfoAlert } from '../../../actions/alerts/alertActions';
import { connect } from 'react-redux';
import CheckupCard from '../checkupCard';

class CheckupsSmartComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checkups: []
        };
    }

    fetchCheckups = () => {
        const _this = this;
        getCheckups().then(function(response) {
            _this.setState({ checkups: response.data });
        });
    }

    onCheckup = (checkup) => {
        const _this = this;
        checkupAbsentee(checkup.id).then(function(response){
            _this.props.createInfoAlert(`You have checked up on ${checkup.person.first_name} ${checkup.person.last_name}. God bless your service!`);
            _this.fetchCheckups();
        });
    }

    componentDidMount() {
        this.fetchCheckups();
    }

    renderCheckups = () => {
        const { checkups } = this.state;
        const _this = this;
        return (
            checkups.map(function(checkup, index) {
                return (
                     <CheckupCard 
                        key={index}
                        checkup={checkup}
                        timezone={_this.props.timezone} 
                        onCheckup={() => _this.onCheckup(checkup)}
                    />
                );
            })
        )
    }

    render() {
        return (
            <div>
                <PageHeader>
                    <h1>Checkups</h1>
                </PageHeader>

                { this.state.checkups.length <= 0 && <p>There are no checkups at the present moment, but once a student in one of your classes is absent you will have checkups appearing here.</p>}
                { this.state.checkups && this.renderCheckups() }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        timezone: state.auth.timezone
    };
}

export default connect(mapStateToProps, { createInfoAlert })(CheckupsSmartComponent);