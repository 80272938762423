import React, { Component } from 'react';
import { Alert, Col, Row} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dismissErrorAlert, dismissInfoAlert } from './actions/alerts/alertActions';

class AlertHandler extends Component {
    static propTypes = {
        infoAlertIsOpen: PropTypes.bool,
        errorAlertIsOpen: PropTypes.bool,
        infoAlertMessage: PropTypes.string,
        errorAlertMessage: PropTypes.string,
        dismissInfoAlert: PropTypes.func,
        dismissErrorAlert: PropTypes.func
    };
    

    constructor(props) {
        super(props);
    }

    renderErrorAlert = () => {
        return (
            <div>
                <Alert style={{ marginBottom: '0px' }}
                    color='danger' 
                    isOpen={this.props.errorAlertIsOpen}
                    toggle={this.props.dismissErrorAlert}
                    type='success'>
                        { this.props.errorAlertMessage }
                </Alert>
            </div>
        )
    }

    renderInfoAlert = () => {
        return (
            <Alert style={{margin: '0px'}} color='success' isOpen={this.props.infoAlertIsOpen} toggle={this.props.dismissInfoAlert}>
                { this.props.infoAlertMessage }
            </Alert>
        )
    }

    render() {
        const { state } = this;
        return (
            <div>
               { this.renderErrorAlert() }
               { this.renderInfoAlert() }
               { this.props.children }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        infoAlertIsOpen: state.alerts.hasInfoAlert,
        errorAlertIsOpen: state.alerts.hasErrorAlert,
        infoAlertMessage: state.alerts.infoAlertMessage,
        errorAlertMessage: state.alerts.errorAlertMessage
    };
}

export default connect(mapStateToProps, { dismissInfoAlert, dismissErrorAlert })(AlertHandler);