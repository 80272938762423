import React from 'react';
import './styles/mobileSidebar.css';
import menuIcon from '../../../images/icons/menu.svg';
import SidebarNavigationContainer from '../../../containers/navigation/sidebarNavigationContainer';

export default class MobileSidebar extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            sidebarOpen: false
        }
    }

    handleSidebarToggle = () => {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen
        });
    }

    render() {
        const {sidebarOpen} = this.state;
        return (
            <div className='mobileNavBar'>
                <img onClick={this.handleSidebarToggle} src={menuIcon} className='menuIcon' alt="icon name"></img>
                <h5 className='siteLogo'>Sycamorus</h5>
                
                { sidebarOpen && <div className='mobileSidebar' onClick={this.handleSidebarToggle}> 
                                        <SidebarNavigationContainer />
                                </div>
                }
            </div>
        );
    }
}