import React, { Component } from 'react';
import {Doughnut} from 'react-chartjs-2';
import propTypes from 'prop-types';


export default class PieGraph extends Component {
    static propTypes = {
        labels: propTypes.array.isRequired,
        data: propTypes.array.isRequired
    }
    render() {

        const data = {
            labels: this.props.labels,
            datasets: [{
                data: this.props.data,
                backgroundColor: [
                'rgb(60, 200, 60)',
                'rgb(255,255,0)',
                'rgb(250, 40, 40)'
                ],
                hoverBackgroundColor: [
                'rgba(100, 200, 100, 1)',
                'rgb(255, 255, 100)',
                'rgb(250, 100, 100)'
                ]
            }]
        };

        return (
            <div>
                <Doughnut data={data} legend={{display: true}} />
            </div>
        )
    }
}