import React, { useState } from 'react';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { resetPassword } from '../../../actions/auth/authActions';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const MyInput = ({ field, form, ...rest }) => {
    //console.log('form', field.name);
    return (
        <div>
            <TextField style={{ whiteSpace: 'normal !important' }} fullWidth={true} variant="outlined" {...field} {...rest} />
            {form.errors[field.name]
                && form.touched[field.name]
                && <div style={{ color: 'red' }}><p>{form.errors[field.name]}</p></div>}
            <br />
        </div>
    )
}

function handleSubmit(token, password, password_confirmation, setIsSubmitted) {
    resetPassword(token, password, password_confirmation)
        .then(response => {
            setIsSubmitted(true);
        })
}

function renderForm(token, setIsSubmitted) {
    return (
        <>
            <h3>Reset Password</h3>
            <p style={{ marginBottom: '40px', color: 'gray' }}>Enter your new password and confirm it to successfully change your password.</p>
            <Formik
                initialValues={{
                    'password': '',
                    'password_confirmation': ''
                }}

                enableReinitialize={true}

                validationSchema={
                    Yup.object().shape({
                        password: Yup.string().required().min(8, 'Password must be at least 8 characters.').max(20, 'Password cannot be more than 20 characters.'),
                        password_confirmation: Yup.string().required().oneOf([Yup.ref('password')], "Passwords don't match."),
                    })
                }

                onSubmit={(values) => handleSubmit(token, values.password, values.password_confirmation, setIsSubmitted)}
            >
                {({ errors, touched, setFieldTouched }) => (
                    <Form>
                        <Field component={MyInput} onInput={() => setFieldTouched('password', true)} type='password' name='password' label='Password' required /><br />
                        <Field component={MyInput} onInput={() => setFieldTouched('password_confirmation', true)} type='password' name='password_confirmation' label='Confirm Password' required />
                        <center>
                            <Button type="submit" disabled={Object.keys(errors).length > 0 || !('password' in touched || 'password_confirmation' in touched)} variant='contained' color='primary' style={{ marginTop: '10px' }}>Submit</Button>
                        </center>
                    </Form>
                )}
            </Formik>
        </>
    );
}

function ResetPassword(props) {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { token } = props.match.params;

    return (
        <Grid container>
            <Grid item md={2} />
            <Grid item md={8}>
                <Paper elevation={3} style={{ padding: '40px' }}>
                    {isSubmitted === false
                        ? renderForm(token, setIsSubmitted)
                        : <p style={{ color: 'green' }}>Password has been reset. You may now <Link to='/login'>log in</Link> with your new password.</p>
                    }
                </Paper>
            </Grid>
            <Grid item md={2} />
        </Grid>
    )
}

export default withRouter(ResetPassword);