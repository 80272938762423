import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import AddChurchClassForm from '../forms/addChurchClassForm';
import { addClass } from '../../../actions/church_classes/churchClassActions';
export default class AddChurchClassModal extends Component {
    static propTypes = {
        toggle: PropTypes.func,
        isOpen: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <Modal size= 'lg' isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}>Add Church</ModalHeader>
                <ModalBody>
                    <AddChurchClassForm
                        afterSubmit={this.props.toggle}
                        addClass={addClass} />           
                </ModalBody>
            </Modal>
        )
    }
}