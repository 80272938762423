import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../pageHeader';
import { getClass, updateClass } from '../../../actions/church_classes/churchClassActions';
import { Redirect } from 'react-router-dom';
import { Button, Row, Col, Input, Form, FormGroup, Label} from 'reactstrap';

export default class UpdateChurchClassForm extends Component {
    static propTypes = {
        church_class: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            church_class: {},
            church_class_updated: false
        };
    }

    handleChange = (e) => {
        this.setState({
            church_class: {...this.state.church_class, [e.target.name]: e.target.value}
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;

        updateClass(this.state.church_class).then(function(response){
            _this.setState({ church_class_updated: true });
        });
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.church_class !== nextProps.church_class){
            this.setState({ church_class: nextProps.church_class });
        }
    }

    renderForm = () => {
        const church_class = this.state.church_class;

        return (
            <Form onSubmit={this.handleSubmit}>
                    <FormGroup row>
                        <Label md={2}>Name*</Label>
                        <Col md={10}> <Input onChange={this.handleChange} 
                            value={church_class.name} name='name' type='text' required /> </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md={2}>Description*</Label>
                        <Col md={10}> <Input onChange={this.handleChange} 
                            value={church_class.description} name='description' type='text' required /> </Col>
                    </FormGroup>
                    <Row>
                        <Col md='10' />
                        <Col md='2'>
                            <Button color='primary'>Update</Button>
                        </Col>
                    </Row>
            </Form>
        )
    }

    render() {
        const church_class = this.state.church_class;
        return (
            <div>
                { this.renderForm() }
                { this.state.church_class_updated && <Redirect to={`/classes/${church_class.id}`} /> }
            </div>
        )
    }
}