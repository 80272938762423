import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Button, Input } from 'reactstrap';
import { sendCSVPeopleImport } from '../../../actions/people/peopleActions';
export default class AddPeopleExcelForm extends Component {
    static propTypes = {
        dismissModalFunc: PropTypes.func
    }
    constructor(props) {
        super(props);

        this.state = {
            file: null
        }
    }
    renderImportPeopleFormInstructions = () => {
        return (
            <div>
                <h4> Guidelines for importing people through excel (.csv) sheet: </h4>
                <ul>
                    <li>Make sure that you are uploading only a .csv file</li>
                    <li>The .csv file must have the following columns in order and the bolded fields <b>MUST</b> have a value:</li>
                        <ul>
                            <li><b>First Name</b></li>
                            <li>Middle Name</li>
                            <li><b>Last Name</b></li>
                            <li>Primary Phone</li>
                            <li>Secondary Phone</li>
                            <li><b>Birthdate</b> <span style={{color: 'red'}}>(** MUST Be in Format of: MM/DD/YYYY or M/D/YYYY **)</span></li>
                            <li>Email</li>
                            <li><b>City</b></li>
                            <li><b>Street</b></li>
                            <li>Zip</li>
                            <li>State</li>
                            <li><b>Country</b></li>
                        </ul>
                </ul>
            </div>
        )
    }

    handleFile = (e) => {

        this.setState({
            file: e.target.files[0]
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        const context = this;
        reader.onload = (function(e) {
                console.log('Result', e.target.result);
                sendCSVPeopleImport(e.target.result)
                    .then(function(response) {
                        //alert('Sent successfully.');
                        if(context.props.dismissModalFunc) {
                            context.props.dismissModalFunc();
                        }
                    });
            });

        reader.readAsText(this.state.file);
    }

    renderForm = () => {
        return (
            <div>
                {this.renderImportPeopleFormInstructions()}<br />
                <center>
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <h4><Label>Choose File:</Label></h4>
                            <Input onChange={this.handleFile} type='file' accept='.csv' />
                        </FormGroup>
                        <Button color='primary'>Import</Button>
                    </Form>
                </center>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderForm()}
            </div>
        )
    }
}