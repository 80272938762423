import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Selectable extends Component {

    static propTypes = {
        states: PropTypes.array,
        onStateChange: PropTypes.func, // func(student_id, state/status) student_id is already passed in parent component
        displayState: PropTypes.bool
    };

    /*
        statesConfig structure
        [
            {
                state: 'Present',
                color: '#EFA'
            },
            {
                state: 'Excused',
                color: '#FFF'
            }
            ...
        ]
    */

    constructor(props) {
        super(props);

        this.state = {
            selectionState: undefined
        };
    }

    toggleSelected = () => {
        const { states } = this.props;
        const _this = this;
        const { selectionState } = this.state;

        const currSelectionStateIndex = states.findIndex((value) => {
            return value.state === selectionState
        })

        const newSelectionState = currSelectionStateIndex !== -1 ? states[(currSelectionStateIndex + 1) % states.length].state : states[0].state;

        //console.log('newSelectionState: ', newSelectionState);

        this.setState({ selectionState: newSelectionState }, () => _this.props.onStateChange(newSelectionState));
    }

    handleClick = () => {
        this.toggleSelected();
    }

    getStateColor = (selectedState) => {
        const { states } = this.props;

        if (selectedState === undefined) {
            return '';
        }

        return states.find((currState) => { return currState.state === selectedState }).color;
    }

    render() {
        const { selectionState } = this.state;
        const style = {
            selected: {
                padding: '10px',
                boxShadow: selectionState !== undefined ? `inset 0px 0px 20px ${this.getStateColor(selectionState)}` : ''
            }
        };

        return (
            <div onClick={this.handleClick} style={style.selected}>
                {this.props.displayState === true && <p style={{ position: 'relative', top: '0' }}>{this.state.selectionState}</p>}
                {this.props.children}
            </div>
        )
    }
}