import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { fetchPerson } from '../../../actions/person/personActions'
import PersonInformation from '../personInformation';
import PersonPhoto from '../personPhoto';
import PageHeader from '../../pageHeader';
import AdminOnly from '../../role_filtering/AdminOnly';
import { deletePerson } from '../../../actions/people/peopleActions';
import { withRouter, Redirect, Link } from 'react-router-dom';
import ConfirmationModal from '../../common/modals/ConfirmationModal';
import unknown_person_logo from '../../../images/coptic_cross.png';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { uploadProfilePic } from '../../../actions/people/peopleActions';
import { createInfoAlert } from '../../../actions/alerts/alertActions';

export class PersonContainer extends Component {

    /* this.props.match.params.id has id of person */

    constructor(props) {
        super(props);

        this.state = {
            person: {},
            isPersonDeleted: false,
            deleteModalIsOpen: false,
            editProfilePic: false
        };
    }

    toggleProfilePicDropzone = () => {
        this.setState({ ...this.state, editProfilePic: !this.state.editProfilePic });
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        const _this = this;

        uploadProfilePic(acceptedFiles[0], this.props.match.params.id).then(function (response) {
            _this.props.createInfoAlert("Profile Pic Successfully uploaded.");
            _this.setState({ ..._this.state, person: { ..._this.state.person, photo_url: response.data.photo_url } });
        });
    }

    retrievePerson = (id) => {
        const context = this;
        fetchPerson(id)
            .then(function (response) {
                console.log(response);
                context.setState({ person: response.data });
            })

    }

    handleDeleteModalToggle = () => {
        this.setState({
            deleteModalIsOpen: !this.state.deleteModalIsOpen
        });
    }

    handleDeletePerson = () => {
        const context = this;
        deletePerson(this.state.person.id)
            .then(function (response) {
                context.setState({
                    isPersonDeleted: true
                });
            });
    }

    componentDidMount() {
        const person_id = this.props.match.params.id;
        console.log(person_id);
        this.retrievePerson(person_id);
    }

    displayParents = () => {
        const { person } = this.state;
        return person.parents.map((parent) => {
            let photo_url = parent.photo_url ? parent.photo_url : unknown_person_logo;
            return (
                <div>
                    <Link to={`/people/${parent.id}`}>
                        <img style={{ 'maxWidth': '100%' }} width='100' height='100' src={photo_url} /> <br />
                        {parent.first_name + " " + parent.last_name}
                    </Link>
                </div>
            )
        });
    }

    displayChildren = () => {
        const { person } = this.state;
        return person.children.map((child) => {
            let photo_url = child.photo_url ? child.photo_url : unknown_person_logo;
            return (
                <div>
                    <Link to={`/people/${child.id}`}>
                        <img style={{ 'maxWidth': '100%' }} width='100' height='100' src={photo_url} /> <br />
                        {child.first_name + " " + child.last_name}
                    </Link>
                </div>
            )
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id === this.props.match.params.id) {
            this.retrievePerson(nextProps.match.params.id);
        } else {
            this.retrievePerson(nextProps.match.params.id);
        }
    }

    render() {
        const { person } = this.state;
        let photo_url = person.photo_url !== undefined && person.photo_url.length != 0 ? person.photo_url : unknown_person_logo;
        const redirectUser = <Redirect to='/people' />;
        console.log("Photo url", photo_url)
        return (
            <div>
                {this.state.isPersonDeleted && redirectUser}
                <PageHeader>
                    <Grid container>
                        <Grid item md='8'>
                            <h1>Person Information</h1>
                        </Grid>
                        <Grid item md='4'>
                            <AdminOnly>
                                <Link to={`/people/${this.state.person.id}/update`}>
                                    <Button color='success'>Update Person</Button>
                                </Link>
                                &nbsp;
                                <Button onClick={this.handleDeleteModalToggle} color='danger'>Delete Person</Button>
                                <ConfirmationModal
                                    title='Delete Person Confirmation'
                                    confirmationAction={this.handleDeletePerson}
                                    isOpen={this.state.deleteModalIsOpen}
                                    toggle={this.handleDeleteModalToggle}>
                                    <p>
                                        Are you sure you want to delete the person entry for {this.state.person.first_name}?
                                    </p>
                                </ConfirmationModal>
                            </AdminOnly>
                        </Grid>

                    </Grid>

                </PageHeader><br /><br />

                <Paper style={{ padding: '20px' }} elevation={3}>
                    <Grid container>
                        <Grid item sm='7' md='4'>
                            <img style={{ 'maxWidth': '100%', borderRadius: '100px' }} width='300' height='300' src={photo_url} /> <br />
                            <br />
                            <Button onClick={this.toggleProfilePicDropzone} color='primary'>Add/Edit Profile Pic</Button>
                            {this.state.editProfilePic && <Dropzone onDrop={this.onDrop}> <br /><br /><br /><p>Click here to upload a photo or drag a photo here</p></Dropzone>}

                        </Grid>

                        <Grid item sm='5' md='8'>
                            <PersonInformation
                                person={person} />
                            {(person.parents !== undefined && person.parents.length != 0) && <div><h4>Parent(s): </h4> {this.displayParents()}</div>}
                            {(person.children !== undefined && person.children.length != 0) && <div> <h4>Children:</h4> {this.displayChildren()}</div>}
                        </Grid>
                    </Grid>
                </Paper>
            </div >
        );
    }
}

export default connect(null, { createInfoAlert }, null, { pure: false })(PersonContainer);