import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SideBarLinks extends Component {

    handleClick = (index) => {
        this.setState({ justForUpdating: false});
    }

    renderLinks = () => {
        const children = this.props.children;
        const state = this.state;
        return (
            <ul>
                {
                    React.Children.map(children, (child, index) => {
                        return (
                            <div onClick={(index) => this.handleClick(index)}>
                                { React.cloneElement(child) }
                            </div>
                        )
                    })
                }
            </ul>
        );
    } 

    render() {
        return this.renderLinks();
    }
}