import React, { Component } from 'react';
import { Row, Col, Input, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import Selectable from '../attendance/selectable';
import unknown_person_logo from '../../images/coptic_cross.png';

export default class SelectablePeopleList extends Component {
    static propTypes = {
        people: PropTypes.array,
        returnSelectionToParent: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedPeopleIDs: []
        };
    }

    handleReturnSelectionToParent = () => {
        let selected_people_ids = [];
        const selectedPeopleIDs = this.state.selectedPeopleIDs;

        for(var key in selectedPeopleIDs){
            if(selectedPeopleIDs[key] === true){
                selected_people_ids.push(key);
            }
        }
        console.log('Selected People Ids', selected_people_ids);
        this.props.returnSelectionToParent(selected_people_ids);
    }

    handleSelection = (id) => {
        if(!this.state.selectedPeopleIDs[id]){
            this.setState({ selectedPeopleIDs: {
                    ...this.state.selectedPeopleIDs,
                    [id]: true
                }
            }, function(){
                this.handleReturnSelectionToParent();
            });
        }
        else{
            this.setState({ selectedPeopleIDs: {
                    ...this.state.selectedPeopleIDs,
                    [id]: !this.state.selectedPeopleIDs[id]
                } 
            }, function(){
                this.handleReturnSelectionToParent();
            });
        }
    }

    renderPersonItem = (person) => {
        const photo_url = person.photo_url ? person.photo_url : unknown_person_logo;
        return (
            <div>
                <p>
                    <img src={photo_url} width='60' height='60' /> {person.first_name} {person.middle_name} {person.last_name}
                </p>
            </div>
        )
    }

    renderPeopleList = () => {
        const _this = this;
        const { people } = this.props;

        return (
            <ul style={{listStyleType: 'none'}}>
                {
                    people.map(function(person){
                        return (
                            <li key={person.id} onClick={ () => _this.handleSelection(person.id) }>
                                <Selectable>
                                    { _this.renderPersonItem(person) }
                                </Selectable>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    render() {
        const style = {
            scrollingDiv: {
                overflowY: 'auto',
                backgroundColor: '#eee',
                padding: '10px',
                height: '200px',
                textAlign: 'left'
            }
        };
        return (
            <div style={style.scrollingDiv}>
                { this.renderPeopleList() }
            </div>
        )
    }
}