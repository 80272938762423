import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavigationSidebar from '../../components/navigation/side_navigation/navigationSidebar';

class SidebarNavigationContainer extends Component {

    static propTypes = {
        first_name: PropTypes.string,
        profile_pic_link: PropTypes.string
    }

    render() {
        return <NavigationSidebar
            first_name={this.props.first_name}
            profile_pic_link={this.props.profile_pic_link}
        />;
    }

}

const mapStateToProps = (state) => {
    return {
        first_name: state.auth.first_name,
        profile_pic_link: state.auth.photo_url
    };
}

export default connect(mapStateToProps, null, null, { pure: false })(SidebarNavigationContainer);