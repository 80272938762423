import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PersonCard from './PersonCard'
export default class PeopleGrid extends Component {

  static propTypes = {
    people: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      people: []
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.people !== nextProps.people) {
      this.setState({ people: nextProps.people });
    }
  }

  showPeople = () => {
    const style = {
      list: {
        display: 'flex',
        listStyleType: 'none',
        flexWrap: 'wrap'
      },
      listItem: {
        padding: '10px'
      },
      linkStyle: {
        color: 'inherit',
        textDecoration: 'none'
      }
    };

    return this.state.people.map(function (person, id) {
      return (
        <Grid item md={3} key={id}>
          <Link style={style.linkStyle} to={`/people/${person.id}`}>
            <PersonCard
              key={id}
              firstName={person.first_name}
              lastName={person.last_name}
              photoURL={person.photo_url} />
          </Link>
        </Grid>
      );
    }
    );
  }

  render() {

    return (
      <Grid container spacing={1} >
        {this.props.people.length === 0 ?
          <p>
            <b>There are no people enlisted in your church yet or you do not have access to view any
                  people if you are not assigned to serve in a class yet.</b>
          </p> :
          this.showPeople()
        }
      </Grid>
    );
  }
}