import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PersonCard from './PersonCard'
import HoverButtons from '../common/hoverButtons';
import ConfirmationModal from '../common/modals/ConfirmationModal';

export default class PeopleGridWithHoverButtons extends Component {
  
  static propTypes = {
    people: PropTypes.array,
    deleteButtonFunc: PropTypes.array
  };

  constructor(props) {
    super(props);
    
    this.state = {
      people: [],
      deleteModalIsOpen: false,
      personToRemove: {}
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.people !== nextProps.people) {
      this.setState({ people: nextProps.people });
    }
  }

  handleDeletePersonToggle = () => {
      this.setState({ deleteModalIsOpen: !this.state.deleteModalIsOpen });
  }

  handleDeletePerson = (person) => {
    const _this = this;

    this.setState({personToRemove: person}, function(){
        _this.handleDeletePersonToggle();
    });
  }

  showPeople = () => {
    const _this = this;
    const style = {
        list: {
          display: 'flex',
          listStyleType: 'none',
          flexWrap: 'wrap'
        },
        listItem: {
          padding: '10px'
        },
        linkStyle: {
          color: 'inherit',
          textDecoration: 'none'
        }
    };

    return (
      <ul style={style.list}>
        {
          this.state.people.map(function(person, id){
            return (
              <li key={id} style={style.listItem}>
                <HoverButtons
                    deleteButtonFunc={() => _this.handleDeletePerson(person)}>
                    <Link style={ style.linkStyle } to={`/people/${person.id}`}>
                    <PersonCard
                        key={id}
                        firstName={person.first_name}
                        lastName={person.last_name}
                        photoURL={person.photo_url} />
                    </Link>
                </HoverButtons>
              </li>
            );
          })
        }
      </ul>   
    );
  }

  render() {
    const personToRemove = this.state.personToRemove;

    return (
      <Container>
        <Row>
          <Col>
            { this.showPeople() }
            <ConfirmationModal
                isOpen={this.state.deleteModalIsOpen}
                title='Remove Person'
                toggle={this.handleDeletePersonToggle}
                confirmationAction={() => this.props.deletePersonFunc(personToRemove.id)}>
                    
                <p>Are you sure you would like to remove {personToRemove.first_name} from their role as servant/student of this class?</p>

            </ConfirmationModal>
          </Col>
        </Row>
      </Container>
    );
  }
}