import { applyMiddleware, createStore, compose } from 'redux';
import { persistCombineReducers , persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

import reducer from './reducers/reducers';

//const middleware = applyMiddleware(promise(), thunk);

const config = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['auth']
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function persistor_store() {
  let store = createStore(persistCombineReducers(config, reducer), composeEnhancers(applyMiddleware(promise(), thunk)));
  let persistor = persistStore(store);

  return { persistor, store };
};
