import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import unknown_person_logo from '../../images/coptic_cross.png';
import ConfirmationModal from '../common/modals/ConfirmationModal';
import moment from 'moment-timezone';

export default class CheckupCard extends Component {
    static propTypes = {
        checkup: PropTypes.object,
        timezone: PropTypes.string,
        onCheckup: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            checkupModalIsOpen: false
        };
    }

    toggleModal = () => {
        this.setState({ checkupModalIsOpen: !this.state.checkupModalIsOpen });
    }

    render() {
        const { checkup } = this.props;
        const { onCheckup } = this.props;
        const photo_url = checkup.person.photo_url ? checkup.person.photo_url : unknown_person_logo;
        const date = moment.utc(checkup.absence_date, 'YYYY-MM-DDHH:mm:ss.SSS').tz(this.props.timezone).format('dddd, MMMM D, YYYY - h:mm:ssa');
        return (
                <Row>
                    <Col md='2'>
                        <img src={photo_url} width='100' height='100' />
                    </Col>
                    <Col md='6'>
                        <p>
                            <Link to={`people/${checkup.person.id}`}>
                                {checkup.person.first_name} {checkup.person.last_name}
                            </Link> from <Link to={`classes/${checkup.church_class.id}`}> {checkup.church_class.name} 
                            </Link> on {date}
                        </p>
                    </Col>
                    <Col md='4'>
                        <ConfirmationModal 
                            title='Confirm Checkup'
                            isOpen={this.state.checkupModalIsOpen}
                            toggle={this.toggleModal}
                            confirmationAction={onCheckup}
                        >
                            <p>I confirm before God that I have checked up on <b>{checkup.person.first_name} {checkup.person.last_name}</b> concerning their absence by phone, email, visitation or any other means.</p>
                        </ConfirmationModal>
                        <Button onClick={this.toggleModal} color='success'>Checked Up</Button>
                    </Col>
                </Row>
        )
    }
}