import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export function fetchPendingUsers() {
    return axios.get(`${API_URL}/users/pending`);
}

export function fetchPendingUsersCount() {
    return axios.get(`${API_URL}/users/pending/count`);
}

export function approveUser(user_id) {
    return axios.post(`${API_URL}/users/approve/${user_id}`);
}

export function denyUser(user_id) {
    return axios.post(`${API_URL}/users/deny/${user_id}`);
}