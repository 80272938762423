import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export function getServantStats(servant_id) {
    return axios.get(`${API_URL}/statistics/servant/${servant_id}/activity`);
}

export function getUpcomingBirthdays(servant_id) {
    return axios.get(`${API_URL}/statistics/${servant_id}/birthdays`);
}

export function getAttendanceData(servant_id) {
    return axios.get(`${API_URL}/statistics/attendances/${servant_id}`);
}

export function getAttendancePieChartData() {
    const servant_id = 1; // Not used right now
    return axios.get(`${API_URL}/statistics/attendances/pie_chart/${servant_id}`)
}