import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

export default class ChurchClassTable extends Component {

    static propTypes = {
        classes: PropTypes.array,
        church_class_link: PropTypes.string
    }

    renderClasses = () => {
        const context = this;
        const class_link = !this.props.church_class_link ? '/classes' : this.props.church_class_link;
        return (
                <tbody>
                {
                    this.props.classes.map(function(church_class) {
                        return (
                            <tr key={church_class.id}>
                            <td>
                                <Link to={`${class_link}/${church_class.id}`}>
                                    { church_class.name }
                                </Link>
                            </td>

                            <td>
                                { church_class.description }
                            </td>
                            </tr>
                        )
                    })
                }
                </tbody>
        )
    }

    renderTable = () => {
        return (
            <Table>
                <thead>
                    <tr>
                        <th>Class Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                { this.renderClasses() }
            </Table>
        )
    }

    render() {
        return (
            <div>
                { this.props.classes.length === 0 ? 
                    <p>
                       <b> No Classes to display. There are either no classes that have been added into your church or 
                        your church administrator has not assigned you to a class yet. </b>
                    </p> :
                    this.renderTable()
                }
                
            </div>
        )
    }
}