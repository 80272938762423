import React, { Component } from 'react';
import PageHeader from '../../pageHeader';
import { Col, Row, Button } from 'reactstrap';
import { getClass } from '../../../actions/church_classes/churchClassActions';
import ChurchClassInformation from '../churchClassInformation';
import AdminOnly from '../../role_filtering/AdminOnly';
import { Link, Redirect } from 'react-router-dom';
import ConfirmationModal from '../../common/modals/ConfirmationModal';
import { deleteClass } from '../../../actions/church_classes/churchClassActions';

export default class ChurchClassContainer extends Component {
    /* this.props.match.params.id has the id of the class */
    
    constructor(props) {
        super(props);

        this.state = {
            church_class: {},
            deleteClassModalIsOpen: false,
            classIsDeleted: false
        };
    }

    componentDidMount() {
        const class_id = this.props.match.params.id;
        const _this = this;

        getClass(class_id)
            .then(function(response){
                _this.setState({ church_class: response.data });
            });
    }

    handleDeleteClassModalToggle = () => {
        this.setState({ deleteClassModalIsOpen: !this.state.deleteClassModalIsOpen });
    }

    handleDeleteClass = () => {
        const _this = this;
        const id = this.state.church_class.id;

        deleteClass(id).then(function(response){
            _this.handleDeleteClassModalToggle();
            _this.setState({ classIsDeleted: true });
        });
    }

    render() {
        const church_class = this.state.church_class;
        return (
            <div>
                <PageHeader>
                    <Row>
                        <Col md={8}>
                            <h1>Church Class Information</h1>
                        </Col>
                        <Col md={4}>
                            <AdminOnly>
                                <Link to={`/classes/${church_class.id}/update`}>
                                    <Button color='success'>Update Class</Button>
                                </Link>
                                &nbsp;
                                <Button onClick={this.handleDeleteClassModalToggle} color='danger'>Delete Class</Button>
                                <ConfirmationModal
                                    title='Delete Class'
                                    isOpen={this.state.deleteClassModalIsOpen}
                                    toggle={this.handleDeleteClassModalToggle}
                                    confirmationAction={this.handleDeleteClass}>
                                    
                                    <p>Are you sure you want to delete the {church_class.name} class?</p>
                                </ConfirmationModal>
                            </AdminOnly>
                        </Col>
                    </Row>
                </PageHeader>
                <br />
                <ChurchClassInformation
                    church_class={this.state.church_class} />

                { this.state.classIsDeleted && <Redirect to='/classes' /> }
            </div>
        )
    }
}