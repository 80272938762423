import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class ConfirmationModal extends Component {
    static propTypes = {
        confirmationAction: PropTypes.func,
        isOpen: PropTypes.bool,
        toggle: PropTypes.func,
        title: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false
        }
    }

    handleConfirmation = () => {
        this.props.confirmationAction();
        this.props.toggle();
    }

    render() {
        return (
            <Modal size= 'lg' isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
                <ModalBody>
                    {this.props.children}             
                </ModalBody>
                <ModalFooter>
                <Button onClick={this.handleConfirmation} color='primary'>Confirm</Button>
                    <Button onClick={this.props.toggle} color='danger'>Cancel</Button>
                </ModalFooter>
                </Modal>
        )
    }
}