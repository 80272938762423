import { 
  FETCH_PEOPLE 
} from '../../actions/people/peopleActions';

const initialState = {
  peopleData: [],
  fetching: false,
  fetched: false,
  error: null
};

export default function reducer(state = initialState, action){
  switch(action.type) {
    case `${FETCH_PEOPLE}_PENDING`: {
      return {
        ...state,
        fetching: true
      };
    }
    case `${FETCH_PEOPLE}_REJECTED`: {
      return {
        ...state,
        fetching: false,
        error: action.payload
      };
    }
    case `${FETCH_PEOPLE}_FULFILLED`: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        peopleData: action.payload.data
      };
    }
    default:
      return state;
  }
};