import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { Input, Button } from 'reactstrap';
import Countries from '../../common/data/countries';
import States from '../../common/data/states';
import { getChurches } from '../../../actions/churches/churchActions';

const MyInput = ({ field, form, ...rest }) => 
   <div>
      <Input {...field} {...rest} />
      {form.errors[field.name] 
        && form.touched[field.name] 
           && <div style={{color:'red'}}>{form.errors[field.name]}</div>}
       <br />
   </div>


export default class AddStudentFormSection extends Component {

    static propTypes = {
        values: PropTypes.array,
        errors: PropTypes.array,
        touched: PropTypes.object,
        churches: PropTypes.array
    };

    constructor(props) {
        super(props);

        this.emptyFieldArray = Object.create(this.props.values.minorStudents);
    }

    render() {

        const { values, errors, touched } = this.props;
        let fieldArray = values.minorStudents;
        const { emptyFieldArray } = this;

        return (
          <div>
              <FieldArray
                    name="minorStudents"
                    render={({ insert, remove, push }) => (
                      <div>
                        {fieldArray.length > 0 &&
                          fieldArray.map((student, index) => (
                              <div>
                                <h2>Child {index + 1}</h2>
                                <hr />
                                <br />
                                <label>First Name</label>
                                <Field
                                  name={`minorStudents.${index}.first_name`}
                                  component={MyInput}
                                  placeholder="First Name"
                                  type="text"
                                />

                                <label>Middle Name</label>
                                <Field
                                  name={`minorStudents.${index}.middle_name`}
                                  component={MyInput}
                                  placeholder="Jane Doe"
                                  type="text"
                                />

                                <label>Last Name</label>
                                <Field
                                  name={`minorStudents.${index}.last_name`}
                                  component={MyInput}
                                  placeholder="Jane Doe"
                                  type="text"
                                />

                                <label>Primary Phone</label>
                                <Field
                                  name={`minorStudents.${index}.primary_phone`}
                                  component={MyInput}
                                  placeholder="Jane Doe"
                                  type="text"
                                />

                                <label>Secondary Phone</label>
                                <Field
                                  name={`minorStudents.${index}.secondary_phone`}
                                  component={MyInput}
                                  placeholder="Jane Doe"
                                  type="text"
                                />

                                <label>Birthdate</label>
                                <Field
                                  name={`minorStudents.${index}.birthdate`}
                                  component={MyInput}
                                  placeholder="Jane Doe"
                                  type="text"
                                />

                                <label>Email</label>
                                <Field
                                  name={`minorStudents.${index}.email`}
                                  component={MyInput}
                                  placeholder="Jane Doe"
                                  type="email"
                                />

                                <label>City</label>
                                <Field
                                  name={`minorStudents.${index}.city`}
                                  component={MyInput}
                                  placeholder="jane@acme.com"
                                  type="text"
                                />

                                <label>Street</label>
                                <Field
                                  name={`minorStudents.${index}.street`}
                                  component={MyInput}
                                  placeholder="Jane Doe"
                                  type="text"
                                />

                                <label>Zip</label>
                                <Field
                                  name={`minorStudents.${index}.zip`}
                                  component={MyInput}
                                  placeholder="Jane Doe"
                                  type="text"
                                />

                                <label>State</label>
                                <Field
                                    name={`minorStudents.${index}.state`}
                                    component={MyInput}
                                    placeholder="Jane Doe"
                                    type="select"
                                >
                                    <option value=''>Select a state</option>
                                    {
                                    Object.keys(States).map(function(abbr, index){
                                        return <option key={index} value={abbr}>{States[abbr]}</option>;
                                    })
                                    }
                                </Field>

                                <label>Country: </label>
                                <Field
                                    name={`minorStudents.${index}.country`}
                                    component={MyInput}
                                    placeholder="Jane Doe"
                                    type="select"
                                >
                                    <option value=''>Select a country</option>
                                    {
                                    Countries.map(function(country, index){
                                        return <option key={index} value={country}>{country}</option>;
                                    })
                                    }
                                </Field>

                                <label>Church</label>
                                <br/>
                                <Field component={MyInput} name={`minorStudents.${index}.church_id`} type='select'>
                                    <option>Select a church</option>
                                    {
                                    this.props.churches.map(function(church, index){
                                        return <option key={index} value={church.id}>{church.name}</option>;
                                    })
                                    }
                                </Field>

                                <Button
                                  color='danger'
                                  onClick={() => remove(index)}
                                >
                                  Remove Child
                                </Button>
                                <br /><br />
                            </div>

                          ))}
                        <Button
                          color='primary'
                          onClick={() => push(emptyFieldArray)}
                        >
                          Add Child
                        </Button>
                      </div>
                    )}
                  />
                </div>
        );
    }
}