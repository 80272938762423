import {
    CREATE_INFO_ALERT,
    CREATE_ERROR_ALERT,
    DISMISS_INFO_ALERT,
    DISMISS_ERROR_ALERT
} from '../../actions/alerts/alertActions';

const initialState = {
    hasInfoAlert: false,
    hasErrorAlert: false,
    infoAlertMessage: '',
    errorAlertMessage: ''
};

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case CREATE_INFO_ALERT: {
            return {
                ...state,
                hasInfoAlert: true,
                infoAlertMessage: action.payload
            };
        }

        case CREATE_ERROR_ALERT: {
            return {
                ...state,
                hasErrorAlert: true,
                errorAlertMessage: action.payload
            };
        }

        case DISMISS_INFO_ALERT: {
            return {
                ...state,
                hasInfoAlert: false,
            };
        }

        case DISMISS_ERROR_ALERT: {
            return {
                ...state,
                hasErrorAlert: false
            };
        }

        default:
            return state;
    }
}