import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ServantOnly extends Component {
    static propTypes = {
        role: PropTypes.string
    };

    render() {
        const isServant = this.props.role === 'Servant';

        return (
            <div>
                { isServant ? this.props.children : '' }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        role: state.auth.role
    };
}

export default connect(mapStateToProps)(ServantOnly);