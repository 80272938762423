import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../pageHeader';
import { Redirect } from 'react-router-dom';
import RegistrationForm from '../forms/registrationForm';
import { registerUser } from '../../../../actions/auth/authActions';
import { getChurches } from '../../../../actions/churches/churchActions';
import { connect } from 'react-redux';
import { createInfoAlert } from '../../../../actions/alerts/alertActions';

class RegisterUserSmartContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            churches: [],
            registrationSuccessful: false
        };
    }

    componentDidMount() {
        const _this = this;
    }

    handleRegisterUser = (params) => {
        const _this = this;

        registerUser(params).then(function(response){
            _this.setState({ registrationSuccessful: true });
            _this.props.createInfoAlert('You have successfully registered. You may login once the church administrator has approved your account.');
        });
    }
    
    render() {
        return (
            <div>
                
                { this.state.registrationSuccessful && <Redirect to='/login' /> }

                <PageHeader>
                    <h1>Register</h1>
                </PageHeader>

                <RegistrationForm 
                    submitFunc={this.handleRegisterUser}
                 />
            </div>
        )
    }
}

export default connect(null, { createInfoAlert })(RegisterUserSmartContainer);