import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

class SidebarLink extends Component {

    static propTypes = {
        text: PropTypes.string,
        link: PropTypes.string,
        location: PropTypes.object.isRequired,
        fetchCountStatus: PropTypes.func,
        notificationCount: PropTypes.number
    }

    constructor(props) {
        super(props);

        this.state = {
            currentPathname: ''
        };

        this.props.history.listen((location, action) => {
            this.setState({ currentPathname: location.pathname});
          });
    }

    renderCountStatus() {

        return (
            <span style={{width:'20px', borderRadius: '5px', position:'absolute', fontSize: '8pt', backgroundColor: 'red'}}>
                {this.props.notificationCount}
            </span>
        );
    }

    render() {

        //console.log('Pathname:', this.props.location.pathname);
        const pathname = this.state.currentPathname === '' ? this.props.location.pathname : this.state.currentPathname;
        const isActive = pathname.includes(this.props.link);
        const {location} = this.props;
        
        return (
           <li className={isActive ? 'sidebar-active-item' : ''}> 
                <NavLink 
                 exact
                 to={this.props.link}>
                 {this.props.text} {(this.props.notificationCount || this.props.notificationCount != 0) && this.renderCountStatus()}
                </NavLink>
            </li>
        );
    }
}

export default withRouter(SidebarLink); 