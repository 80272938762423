import React, { Component } from 'react';
import { getClasses } from '../../../actions/church_classes/churchClassActions';
import PageHeader from '../../pageHeader';
import ChurchClassTable from '../../church_classes/churchClassTable';

export default class AttendanceSmartComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            classes: []
        }
    }

    fetchChurchClasses = () => {
        const context = this;
        getClasses()
            .then(function(response) {
                context.setState({ classes: response.data });
            });
    }

    componentDidMount() {
        this.fetchChurchClasses();
    }

    render() {
        return (
            <div>
                <PageHeader>
                    <h1>Choose Class to take attendance in</h1>
                </PageHeader>
                <ChurchClassTable
                    classes={this.state.classes}
                    church_class_link='/attendance' />
            </div>
        )
    }

}