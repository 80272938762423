import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import SidebarNavigationContainer from './navigation/sidebarNavigationContainer';
import MobileSidebarNavigation from '../components/navigation/side_navigation/mobileSidebarNavigation';

export default class SiteLayout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            width: 0
        };

        window.addEventListener('resize', this.setWidth);
    }

    setWidth = () => {
        this.setState({
            width: window.innerWidth
        });
    }

    componentDidMount() {
        this.setWidth();
    }

    renderFullscreenLayout = () => {
        return (
            <Container fluid={true}>
                <Row>
                    <Col style={{ paddingLeft: '0px' }} xs='2' sm='4' md='2'>
                        <SidebarNavigationContainer />
                    </Col>
                    
                    <Col xs='10' sm='8' md='10'>  
                        <br />
                        {
                            this.props.children
                        }     
                    </Col>
                </Row>
            </Container>
        );
    }

    renderMobileLayout = () => {
        return (
            <Container fluid={true}>
                <Row>
                    <MobileSidebarNavigation />
                    <br /><br />
                </Row>
                
                <Row style={{padding: '15px'}}>
                    { this.props.children }
                </Row>
            </Container>
        )
    }

    render() {
        const { width } = this.state;

        return (
            <div>
                { width < 700 && this.renderMobileLayout() }
                { width >= 700 && this.renderFullscreenLayout() }
            </div>
        )
    }
}