import React from 'react';
import PageHeader from '../../components/pageHeader';
import BirthdayPeopleList from '../../components/dashboard/widgets/birthdayPeopleList';
import AttendanceGraph from '../../components/dashboard/widgets/attendanceGraph';
import AttendancePieChart from '../../components/dashboard/widgets/attendancePieChart';
import Widget from '../../components/dashboard/widget';
import { 
    Paper, 
    Grid , 
    Typography,
    makeStyles
} from '@material-ui/core';
import UserActivityList from '../../components/dashboard/widgets/userActivityList';

const useStyles = makeStyles({
    root: {

    }
})

export default function DashboardContainer() {
    const classes = useStyles();

    return (
        <div>
            <PageHeader>
                <h1>Dashboard</h1>
            </PageHeader>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget>
                        <Typography variant="h5">
                            Attendance By Month for Classes
                        </Typography><br />
                        <AttendanceGraph style={{ height: '400px' }} /> 
                    </Widget>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Widget>
                       <Typography variant='h5'>
                           My Activity
                       </Typography><br />
                        <UserActivityList />
                    </Widget>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Widget>
                        <Typography variant='h5'>
                            Attendance Data for the Year
                        </Typography><br />
                        <AttendancePieChart />
                    </Widget>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Widget>
                        <Typography variant='h5'>
                            Birthdays of the Month
                        </Typography><br />
                        <BirthdayPeopleList />
                    </Widget>
                </Grid>
            </Grid>
        </div>
    )
}
