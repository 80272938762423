import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

export default class CollapsableSection extends Component {
  
  static propTypes = {
    title: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };

  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    const style = {
      collapseTitleDiv: {
        backgroundColor: 'rgba(22, 233, 18, 0.6)',
        border: '1px solid rgb(0, 0, 0)',
        marginBottom: '10px',
        padding: '10px',
        cursor: 'pointer',
        borderRadius: '8px'
      }
    };

    return (
      <div>
        <div onClick={this.toggle} style={style.collapseTitleDiv}>{this.props.title}</div>
        <Collapse isOpen={this.state.collapse}>
          <Card style={{marginBottom: '10px'}}>
            <div style={{padding: '20px'}}>
              {this.props.children}
            </div>
          </Card>
        </Collapse>
      </div>
    );
  }
}
