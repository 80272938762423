import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarUser from './sidebarUser';
import SidebarLink from './sidebarLink';
import SidebarLinks from './sidebarLinks';
import AdminOnly from '../../role_filtering/AdminOnly';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { logout, invalidateLogin } from '../../../actions/auth/authActions';
import { createInfoAlert } from '../../../actions/alerts/alertActions';
import { Redirect } from 'react-router-dom';
import { getCheckupsCount, getPendingUsersCount } from '../../../actions/notifications/notificationActions';
import './styles/navigationSidebar.css';

class NavigationSidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedOut: false,
            checkupCounterIntervalHandle: null,
            pendingUsersCounterIntervalHandle: null,
            API_CALL_INTERVAL: 3000
        };
    }
    static propTypes = {
        first_name: PropTypes.string,
        profile_pic_link: PropTypes.string
    }

    logout = () => {
        const _this = this;

        this.props.logout();
    }

    renderLogoutButton = () => {
        return (
            <Button
                onClick={this.logout}
                color='danger'>Logout</Button>
        );
    }

    fetchCheckupCounter() {
        const _this = this;
        const { logged_in } = this.props;

        this.checkup_counter_interval = setInterval(function () {
            _this.props.getCheckupsCount();
        }, this.state.API_CALL_INTERVAL);

        //console.log('checkup_counter_interval initiating', this.checkup_counter_interval);
    }

    fetchPendingUsersCounter() {
        const _this = this;
        const { logged_in } = this.props;

        this.pending_users_interval = setInterval(function () {
            _this.props.getPendingUsersCount();
        }, this.state.API_CALL_INTERVAL);

        // console.log('pending_users_interval intiating', this.pending_users_interval);
    }

    componentDidMount() {
        if (this.props.logged_in)
            this.startNotificationIntervals();
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextprops', nextProps.logged_in);
        if (nextProps.logged_in === false) {
            this.clearNotificationIntervals();
        }
        else if (nextProps.logged_in === true) {
            this.clearNotificationIntervals();
            this.startNotificationIntervals();
        }
    }

    startNotificationIntervals = () => {
        this.fetchCheckupCounter();
        this.fetchPendingUsersCounter();
    }

    clearNotificationIntervals = () => {
        // console.log('users notification exiting', this.pending_users_interval);
        // console.log('checkups notification exiting', this.checkup_counter_interval);
        clearInterval(this.pending_users_interval);
        clearInterval(this.checkup_counter_interval);
    }

    componentWillUnmount() {
        this.clearNotificationIntervals();
    }

    renderMenu = (logged_in) => {
        return (
            <div className='sidebar'>

                <div className='sidebar-content'>

                    <div className='sidebar-app-title'>
                        <h3>Sycamorus</h3>
                    </div>

                    <SidebarUser
                        first_name={this.props.first_name}
                        profile_pic_link={this.props.profile_pic_link}
                    />

                    <SidebarLinks>
                        <SidebarLink text='Dashboard' link='/dashboard' />
                        <AdminOnly><SidebarLink text='Users' link='/users/pending' notificationCount={this.props.pending_users_count} /></AdminOnly>
                        <SidebarLink text='People' link='/people' />
                        <SidebarLink text='Checkups' link='/checkups' notificationCount={this.props.checkups_count} />
                        <SidebarLink text='Classes' link='/classes' />
                        <SidebarLink text='Take Attendance' link='/attendance' />
                    </SidebarLinks>

                    {logged_in && this.renderLogoutButton()}
                </div>
            </div>
        )
    }

    renderEmptyMenu = () => {
        return (
            <div className='sidebar'>
                <div className='sidebar-content'>
                    <div className='sidebar-app-title'>
                        <h3>Sycamorus</h3>
                    </div>
                    <SidebarUser
                        first_name={this.props.first_name}
                        profile_pic_link={this.props.profile_pic_link}
                    />
                </div>
            </div>
        )
    }

    render() {
        console.log(this.props.logged_in);
        return (
            <>
                {this.props.logged_in === true
                    ? this.renderMenu(this.props.logged_in)
                    : this.renderEmptyMenu()
                }
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        logged_in: state.auth.logged_in,
        checkups_count: state.notifications.checkups_count,
        pending_users_count: state.notifications.pending_users_count
    };
}

export default connect(mapStateToProps, { logout, invalidateLogin, createInfoAlert, getCheckupsCount, getPendingUsersCount })(NavigationSidebar);