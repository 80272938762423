import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getClasses } from '../../../actions/church_classes/churchClassActions';
import { Row, Col, Button} from 'reactstrap';
import SuperAdminOnly from '../../role_filtering/superAdminOnly';
import PageHeader from '../../../components/pageHeader';
import ChurchClassTable from '../churchClassTable';
import AddChurchClassModal from '../modals/addChurchClassModal';
import AdminOnly from '../../role_filtering/AdminOnly';

export default class ChurchClassesContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            classes: [],
            addClassModalIsOpen: false
        };
    }


    fetchChurches = () => {
        const context = this;
        getClasses()
            .then(function(response) {
                context.setState({ classes: response.data });
            });
    }

    componentDidMount() {
        this.fetchChurches();
    }
    
    handleToggle = () => {
        if(this.state.addClassModalIsOpen) {
            this.fetchChurches();
        }

        this.toggleAddClassModal();
    }

    toggleAddClassModal = () => {
        this.setState({ addClassModalIsOpen: !this.state.addClassModalIsOpen });
    }

    render() {
        const church_class_link = '/classes';
        return (
            <div>
                <PageHeader>
                    <Row>
                        <Col md={8}>
                            <h1>Church Class Information</h1>
                        </Col>
                        <Col md={4}>
                            <AdminOnly>
                                <Button onClick={this.handleToggle} style={{marginTop:'10px'}} color='primary'>Add New Class</Button>
                                <AddChurchClassModal
                                    isOpen={this.state.addClassModalIsOpen}
                                    toggle={this.handleToggle} />
                            </AdminOnly>
                        </Col>
                    </Row>
                </PageHeader>

                <ChurchClassTable 
                    classes = { this.state.classes }
                    church_class_link={church_class_link} />
            </div>
        )
    }

}