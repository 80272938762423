import React, { useState } from 'react';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { forgotPassword } from '../../../actions/auth/authActions';
import * as Yup from 'yup';

const MyInput = ({ field, form, ...rest }) => {
    //console.log('form', field.name);
    return (
        <div>
            <TextField style={{ whiteSpace: 'normal !important' }} fullWidth={true} variant="outlined" {...field} {...rest} />
            {form.errors[field.name]
                && form.touched[field.name]
                && <div style={{ marginBottom: '-50px', color: 'red' }}><p>{form.errors[field.name]}</p></div>}
            <br />
        </div>
    )
}

function handleSubmit(email, setIsSubmitted) {
    forgotPassword(email)
        .then(response => {
            setIsSubmitted(true);
        })
}

function renderForm(setIsSubmitted) {
    return (
        <>
            <h3>Forgot Password</h3>
            <p style={{ color: 'gray', marginBottom: '40px' }}>Enter the email associated with your account and a reset password link will be sent.</p>
            <Formik
                initialValues={{
                    'email': ''
                }}

                enableReinitialize={true}

                validationSchema={
                    Yup.object().shape({
                        email: Yup.string().required().email('Email is not valid. (Ex: emad@gmail.com)')
                    })
                }

                onSubmit={(values) => {
                    handleSubmit(values.email, setIsSubmitted);
                }}
            >
                {({ errors, touched, setFieldTouched }) => (
                    <Form>
                        <Field component={MyInput} onInput={() => setFieldTouched('email', true)} name='email' label='Email Address' required />
                        <center>
                            <Button type="submit" disabled={Object.keys(errors).length > 0 || !('email' in touched)} variant='contained' color='primary' style={{ marginTop: '10px' }}>Submit</Button>
                        </center>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default function ForgotPasswordEmailForm() {
    const [isSubmitted, setIsSubmitted] = useState(false);

    return (
        <Grid container>
            <Grid item md={2} />
            <Grid item md={8}>
                <Paper elevation={3} style={{ padding: '40px' }}>
                    {isSubmitted === false
                        ? renderForm(setIsSubmitted)
                        : <p style={{ color: 'green' }}>Email has been sent with further instructions on completing password reset.</p>}
                </Paper>
            </Grid>
            <Grid item md={2} />
        </Grid>
    )
}