import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PersonPhoto extends Component {

    static propTypes = {
        photo_url: PropTypes.string
    }

    render() {
        return (
            <img width='300' height='300' src={ this.props.photo_url } />
        )
    }
}