import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';

export default class PendingUserApprovalTable extends Component {

    static propTypes = {
        fetchPendingUsers: PropTypes.func,
        approveUser: PropTypes.func,
        denyUser: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            pendingUsers: []
        };
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers = () => {
        const _this = this;

        this.props.fetchPendingUsers().then(function (response) {
            _this.setState({ pendingUsers: response.data });
        });
    }

    approveUser = (user_id) => {
        const _this = this;

        this.props.approveUser(user_id).then(function (response) {
            _this.fetchUsers();
        });
    }

    denyUser = (user_id) => {
        const _this = this;

        this.props.denyUser(user_id).then(function (response) {
            _this.fetchUsers();
        });
    }

    renderUserItem = (user) => {
        return (
            <tr>
                <td style={{ maxWidth: '50px' }}>{user.email}</td>
                <td>{user.person.first_name}</td>
                <td>{user.person.last_name}</td>
                <td>
                    <Button
                        onClick={() => this.approveUser(user.id)}
                        color='success'>
                        √
                    </Button>
                </td>
                <td>
                    <Button
                        onClick={() => this.denyUser(user.id)}
                        color='danger'>
                        X
                    </Button>
                </td>
            </tr>
        );
    }

    renderTable = () => {
        const _this = this;

        return (
            <TableContainer component={Paper}>
                <Table stickyHeader style={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Approve</TableCell>
                            <TableCell>Deny</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.pendingUsers.map((user, id) => {
                            const { person } = user;
                            return (
                                <TableRow hover key={id}>
                                    <TableCell style={{ maxWidth: '100px', wordWrap: 'break-word' }}>{person.email}</TableCell>
                                    <TableCell style={{ maxWidth: '100px', wordWrap: 'break-word' }}>{person.first_name}</TableCell>
                                    <TableCell style={{ maxWidth: '20px', wordWrap: 'break-word' }}>{person.last_name}</TableCell>
                                    <TableCell style={{ maxWidth: '100px', wordWrap: 'break-word' }}>
                                        <Button
                                            onClick={() => this.approveUser(user.id)}
                                            color='success'>
                                            √
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ maxWidth: '20px', wordWrap: 'break-word' }}>
                                        <Button
                                            onClick={() => this.denyUser(user.id)}
                                            color='danger'>
                                            X
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    render() {
        return (
            <div>
                {this.state.pendingUsers.length === 0 ?
                    <p>
                        <b>No pending users at this time.</b>
                    </p> :
                    this.renderTable()}
            </div>
        );
    }
}