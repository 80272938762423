import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import SelectablePeopleList from '../selectablePeopleList';
import unknown_person_logo from '../../../images/coptic_cross.png'

export default class SelectPeopleModal extends Component {
    static propTypes = {
        title: PropTypes.string,
        toggle: PropTypes.func,
        isOpen: PropTypes.bool,
        onSubmission: PropTypes.func,
        returnSelectedPeopleToParent: PropTypes.func,
        people: PropTypes.array
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedPeopleIds: []
        };
    }

    handleSubmission = () => {
        this.props.onSubmission();
        this.props.toggle();
    }

    handlePersonSelection = (person) => {
        const selectedPeopleIds = [...this.state.selectedPeopleIds];
        if (selectedPeopleIds.includes(person.id)) {
            selectedPeopleIds.splice(selectedPeopleIds.indexOf(person.id), 1);
        } else {
            selectedPeopleIds.push(person.id);
        }

        this.setState({ selectedPeopleIds: selectedPeopleIds });
        this.props.returnSelectedPeopleToParent(selectedPeopleIds);
    }

    renderSelectablePeopleList = (people) => {
        const _this = this;
        return (
            <List style={{ backgroundColor: '#eee', height: '600px', overflow: 'scroll' }}>
                {people.map((person, index) => {
                    const isSelected = this.state.selectedPeopleIds.includes(person.id);
                    return (
                        <ListItem style={{ backgroundColor: isSelected === true ? 'rgba(0, 89, 198, .77)' : '', cursor: 'pointer' }} onClick={() => _this.handlePersonSelection(person)}>
                            <ListItemAvatar>
                                <img style={{ width: '100px', height: '100px', objectFit: 'contain' }} src={person.photo_url ? person.photo_url : unknown_person_logo} />
                            </ListItemAvatar>
                            <ListItemText style={{ marginLeft: '20px' }} primary={`${person.first_name} ${person.middle_name ? person.middle_name : ''} ${person.last_name}`} />
                        </ListItem>
                    )
                })}
            </List>
        )
    }

    render() {
        const { title, toggle, isOpen, onSubmission, people } = this.props;

        return (
            <Modal size='lg' isOpen={isOpen} toggle={toggle} className={this.props.className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <p>Select People from the following list:</p>
                    {this.renderSelectablePeopleList(people)}
                    {/*<SelectablePeopleList
                        people={people}
                    returnSelectionToParent={this.props.returnSelectedPeopleToParent} /> */}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.handleSubmission} color='primary'>Select</Button>
                </ModalFooter>
            </Modal>
        )
    }
}