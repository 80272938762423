export const CREATE_INFO_ALERT = 'CREATE_INFO_ALERT';
export const CREATE_ERROR_ALERT = 'CREATE_ERROR_ALERT';
export const DISMISS_INFO_ALERT = 'DISMISS_INFO_ALERT';
export const DISMISS_ERROR_ALERT = 'DISMISS_ERROR_ALERT';

export function createInfoAlert(message) {
    return {
        type: CREATE_INFO_ALERT,
        payload: message
    };
}

export function createErrorAlert(message) {
    return {
        type: CREATE_ERROR_ALERT,
        payload: message
    };
}

export function dismissInfoAlert() {
    return {
        type: DISMISS_INFO_ALERT
    };
}

export function dismissErrorAlert() {
    return {
        type: DISMISS_ERROR_ALERT
    };
}