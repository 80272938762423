import React, { Component } from 'react';
import { Button, Col, Row, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import PageHeader from '../../pageHeader';
import { getClass, updateClass } from '../../../actions/church_classes/churchClassActions';
import UpdateChurchClassForm from '../forms/updateChurchClassForm';

export default class UpdateChurchClassContainer extends Component {

    /* this.props.match.params.id contains the id of the church class */

    constructor(props) {
        super(props);

        this.state = {
            church_class: {}
        };
    }

    componentDidMount() {
        const _this = this;
        const class_id = this.props.match.params.id;

        getClass(class_id).then(function(response){
            _this.setState({ church_class: response.data });
        });
    }

    render() {
        return (
            <div>
                <PageHeader>
                    <h1>Update Church Class</h1>
                </PageHeader>
                <UpdateChurchClassForm 
                    church_class={this.state.church_class} />
            </div>
        )
    }
}