import axios from 'axios';
import qs from 'qs';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const API_URL = process.env.REACT_APP_API_URL;

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const INVALIDATE_LOGIN = 'INVALIDATE_LOGIN';

export function login(email, password) {
  return {
    type: LOGIN,
    payload: axios.post(`${AUTH_URL}/sign_in`, qs.stringify({ email: email, password: password }))
  }
}

export function validateToken(access_token, client_token, uid) {
  return {
    type: VALIDATE_TOKEN,
    payload: axios.get(`${AUTH_URL}/validate_token`, {
      params: {
        'access-token': access_token,
        client: client_token,
        uid: uid
      }
    })
  }
}

export function invalidateLogin() {
  return {
    type: INVALIDATE_LOGIN
  };
}

export function logout() {
  return {
    type: LOGOUT,
    payload: axios.delete(`${AUTH_URL}/sign_out`)
  };
}

export function registerUser(params) {
  return axios.post(`${AUTH_URL}/`, params);
}

export function forgotPassword(email) {
  return axios.post(`${API_URL}/users/forgot_password`, { email: email })
}

export function resetPassword(token, password, password_confirmation) {
  return axios.post(`${API_URL}/users/reset_password`, {
    token: token,
    password: password,
    password_confirmation: password_confirmation
  })
}