import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export default class HoverButtons extends Component {
    static propTypes = {
        deleteButtonFunc: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            deleteButtonVisible: false
        }
    }

    renderDeleteButton = () => {
        const deleteButtonStyle = {
            position: 'relative',
            top: '10px',
            left: '95%',
            zIndex: '1'
        };

        return (
            <Button
                size='sm'
                color='danger'
                style={deleteButtonStyle}
                onClick={this.props.deleteButtonFunc}>
                x
            </Button>
        );
    }

    toggleDeleteButton = () => {
        this.setState({ deleteButtonVisible: !this.state.deleteButtonVisible });
    }

    toggleButtons = () => {
        this.toggleDeleteButton();
    }

    render() {
        return (
            <div onMouseEnter={this.toggleButtons} onMouseLeave={this.toggleButtons}>
                {this.renderDeleteButton()}
                {this.props.children}
            </div>
        )
    }
}