import React from 'react';
import {
    Paper,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        padding: '20px'
    }
})

export default function Widget(props) {
    const classes = useStyles();

    return (
        <Paper elevation={3} className={classes.root}>
            {props.children}
        </Paper>
    );
}