// Masks phone number input as (XXX)-XXX-XXXX
import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

function formatNumber(number) {
    return `(${number.substr(0, 3)})-${number.substr(3, 3)}-${number.substr(6, 4)}`;
}

function handleChange(event, phoneNumberStateSetter, parentChangedValueFunc) {
    let cleanedNumber = new String(event.target.value).replace(/\D/g, '');
    let formattedNumber = '';
    if (cleanedNumber.length > 6) {
        formattedNumber = `(${cleanedNumber.substr(0, 3)})-${cleanedNumber.substr(3, 3)}-${cleanedNumber.substr(6, 4)}`
    } else if (cleanedNumber.length > 3) {
        formattedNumber = `(${cleanedNumber.substr(0, 3)})-${cleanedNumber.substr(3, 3)}`;
    } else if (cleanedNumber.length > 0) {
        formattedNumber = `(${cleanedNumber.substr(0, 3)}`;
    } else {
        formattedNumber = '';
    }

    phoneNumberStateSetter(formattedNumber);
    parentChangedValueFunc(formattedNumber);
}


function MaskedPhoneNumberInput(props) {
    const { onChangedValue } = props;

    let [phoneNumber, setPhoneNumber] = useState('');
    //console.log("PROPS:::: ", props);
    return (
        React.cloneElement(props.children, {
            onChange: (e) => handleChange(e, setPhoneNumber, onChangedValue)
        })
    )
}

MaskedPhoneNumberInput.propTypes = {
    onChangedValue: PropTypes.func.isRequired,
    initialValue: PropTypes.string
}

export default MaskedPhoneNumberInput;