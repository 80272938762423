import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import AdminOnly from '../role_filtering/AdminOnly';
import ServantOnly from '../role_filtering/ServantOnly';
import PageHeader from '../pageHeader';
import { getServantsOfClass, 
        getStudentsOfClass,
        addServantsToClass,
        addStudentsToClass,
        removeServantFromClass,
        removeStudentFromClass 
    } from '../../actions/church_classes/churchClassActions';
import { getAllServants, getAllStudents } from '../../actions/people/peopleActions';
import PeopleGrid from '../people/PeopleGrid';
import PeopleGridWithHoverButtons from '../people/PeopleGridWithHoverButtons';
import SelectPeopleModal from '../people/modals/selectPeopleModal';

export default class ChurchClassInformation extends Component {
    static propTypes = {
        church_class: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            servants: [],
            students: [],
            selectServantsModalIsOpen: false,
            selectStudentsModalIsOpen: false,
            all_servants: [],
            all_students: [],
            selectedServantsToAdd: [],
            selectedStudentsToAdd: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.church_class !== this.props.church_class) {
            const _this = this;
            const class_id = nextProps.church_class.id;

            getStudentsOfClass(class_id).then(function(response){
                _this.setState({ students: response.data });
            });

            getServantsOfClass(class_id).then(function(response){
                _this.setState({ servants: response.data });
            });

            getAllServants().then(function(response){
                _this.setState({ all_servants: response.data });
            })

            getAllStudents().then(function(response){
                _this.setState({ all_students: response.data });
            })
        }
    }

    removeIntersection = (arrayA, arrayB) => {
        var intersectingItems = [];
        const arrayA_strings = arrayA.map(function(item){ return JSON.stringify(item)});
        const arrayB_strings = arrayB.map(function(item){ return JSON.stringify(item)});

        const nonIntersectingItems = arrayA_strings.filter(n => !arrayB_strings.includes(n));

        return nonIntersectingItems.map(function(item){ return JSON.parse(item); });
    }

    filterSelectableServants = () => {
        return this.removeIntersection(this.state.all_servants, this.state.servants);
    }

    filterSelectableStudents = () => {
        return this.removeIntersection(this.state.all_students, this.state.students);
    }

    fetchStudentsOfClass = () => {
        const _this = this;
        const class_id = this.props.church_class.id;

        getStudentsOfClass(class_id).then(function(response){
            _this.setState({ students: response.data });
        });
    }

    fetchServantsOfClass = () => {
        const _this = this;
        const class_id = this.props.church_class.id;

        getServantsOfClass(class_id).then(function(response){
            _this.setState({ servants: response.data });
        });
    }

    handleSelectServantsToggle = () => {
        this.setState({ selectServantsModalIsOpen: !this.state.selectServantsModalIsOpen });
    }

    handleSelectStudentsToggle = () => {
        this.setState({ selectStudentsModalIsOpen: !this.state.selectStudentsModalIsOpen });
    }

    handleSelectServantsSubmission = () => {
        const _this = this;

        addServantsToClass(this.state.selectedServantsToAdd, this.props.church_class.id).then(function(response){
            //alert('Successfully sent servants to server.');
            _this.fetchServantsOfClass();
        });
    }

    handleSelectStudentsSubmission = () => {
        const _this = this;

        addStudentsToClass(this.state.selectedStudentsToAdd, this.props.church_class.id).then(function(response){
            //alert('Succesfully sent students to server.');
            _this.fetchStudentsOfClass();
        });
    }

    handleSelectedServants = (servant_ids) => {
        console.log('Selected Servant IDs', servant_ids);
        this.setState({ selectedServantsToAdd: servant_ids });
    }

    handleSelectedStudents = (student_ids) => {
        console.log('Selected Student IDs', student_ids);
        this.setState({ selectedStudentsToAdd: student_ids });
    }

    handleRemoveServantFromClass = (servant_id) => {
        const church_id = this.props.church_class.id;
        const _this = this;

        removeServantFromClass(servant_id, church_id).then(function(response){
            _this.fetchServantsOfClass();
        });
    }

    handleRemoveStudentFromClass = (student_id) => {
        const church_id = this.props.church_class.id;
        const _this = this;
        
        removeStudentFromClass(student_id, church_id).then(function(response){
            _this.fetchStudentsOfClass();
        });
    }

    render() {
        const church_class = this.props.church_class;
        const {selectStudentsModalIsOpen, selectServantsModalIsOpen, servants, students} = this.state;
        return (
            <div>
                <Row>
                    <Col md={6}>
                        <h4>Class Title</h4>
                        <p>&nbsp;{church_class.name}</p>
                    </Col>
                    <Col md={6}>
                        <h4>Class Description</h4> 
                        <p>&nbsp;{church_class.description}</p>
                    </Col>
                </Row>
                <br />
                
                <div id='servants' style={{backgroundColor: '#eee', padding: '10px'}}>
                    <Row>
                        <Col md={8}>
                            <h3><u>Servants</u></h3>
                        </Col>
                        <Col md={4}>
                            <AdminOnly>
                                <Button onClick={this.handleSelectServantsToggle}>Add Servant(s)</Button>
                                <SelectPeopleModal
                                    title='Select Servants'
                                    people={this.filterSelectableServants()}
                                    isOpen={selectServantsModalIsOpen}
                                    toggle={this.handleSelectServantsToggle}
                                    onSubmission={this.handleSelectServantsSubmission}
                                    returnSelectedPeopleToParent={this.handleSelectedServants} />
                            </AdminOnly>
                        </Col>
                    </Row>
                    <br />
                    <AdminOnly>
                        <PeopleGridWithHoverButtons
                            people={this.state.servants}
                            deletePersonFunc={this.handleRemoveServantFromClass} />
                    </AdminOnly>

                    <ServantOnly>
                        <PeopleGrid
                            people={this.state.servants} />
                    </ServantOnly>
                    <br />
                </div>

                &nbsp;
                
                <div id='students' style={{backgroundColor: '#eee', padding: '10px'}}>
                    <Row>
                        <Col md={8}>
                            <h3><u>Students</u></h3>
                        </Col>
                        <Col md={4}>
                            <AdminOnly>
                                <Button onClick={this.handleSelectStudentsToggle}>Add Student(s)</Button>
                                <SelectPeopleModal
                                    title='Select Students to Enroll'
                                    people={this.filterSelectableStudents()}
                                    isOpen={selectStudentsModalIsOpen}
                                    toggle={this.handleSelectStudentsToggle}
                                    onSubmission={this.handleSelectStudentsSubmission}
                                    returnSelectedPeopleToParent={this.handleSelectedStudents} />
                            </AdminOnly>
                        </Col>
                    </Row>
                    <br />
                    <AdminOnly>
                        <PeopleGridWithHoverButtons
                            people={this.state.students}
                            deletePersonFunc={this.handleRemoveStudentFromClass} />
                    </AdminOnly>

                    <ServantOnly>
                        <PeopleGrid
                            people={this.state.students} />
                    </ServantOnly>
                </div>

            </div>
        )
    }
}