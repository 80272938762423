import {
  LOGIN,
  LOGOUT,
  VALIDATE_TOKEN,
  INVALIDATE_LOGIN
} from '../../actions/auth/authActions';

const initialState = {
  access_token: '',
  client_token: '',
  user_id: '',
  uid: '',
  logged_in:  false,
  logging_in: true,
  first_name: '',
  last_name: '',
  photo_url: '',
  role: '',
  church_id: '',
  timezone: ''
};

const headers_response = (action) => {
  return { headers: action.payload.headers, response: action.payload.data };
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case `${LOGIN}_PENDING`: {
      return {
        ...state,
        logging_in: true
      }
    }

    case `${LOGIN}_REJECTED`: {
      return initialState;
    }

    case `${LOGIN}_FULFILLED`: {
      const {headers, response} = headers_response(action);
      return {
        ...state,
        logging_in: false,
        logged_in: true,
        user_id: response.data.user_id,
        access_token: headers['access-token'],
        client_token: headers.client,
        uid: headers.uid,
        first_name: response.data.first_name,
        last_name: response.data.last_name,
        photo_url: response.data.photo_url,
        role: response.data.role,
        church_id: response.data.church_id,
        timezone: response.data.timezone
      }
    }

    case `${VALIDATE_TOKEN}_PENDING`: {
      return {
        ...state,
        logging_in: true
      }
    }

    case `${VALIDATE_TOKEN}_REJECTED`: {
      return {...state, logged_in: false};
    }

    case `${VALIDATE_TOKEN}_FULFILLED`: {
      const {headers, response} = headers_response(action);
      return {
        ...state,
        logged_in: true,
      }
    }

    case `${LOGOUT}_FULFILLED`: {
      return {
        ...state,
        photo_url: '',
        first_name: '',
        role: '',
        logged_in: false
      };
    }

    case `${INVALIDATE_LOGIN}`: {
      return {
        logged_in: false
      }
    }
    default:
      return state;
  }
}