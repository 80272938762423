import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PeopleGrid from '../components/people/PeopleGrid';
import PeopleTable from '../components/people/PeopleTable';
import PageHeader from '../components/pageHeader';
import { Button, Row, Col } from 'reactstrap';
import AdminOnly from '../components/role_filtering/AdminOnly';
import AddPeopleModal from '../components/people/modals/addPeopleModal';
import PeopleList from '../components/people/peopleList';
import { createErrorAlert, createInfoAlert } from '../actions/alerts/alertActions';

import {
  fetchPeople
} from '../actions/people/peopleActions';

class PeopleContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addPeopleModalIsOpen: false
    }
  }
  componentDidMount() {
    this.props.fetchPeople();
  }

  toggleAddPeopleModal = () => {
    this.setState({
      addPeopleModalIsOpen: !this.state.addPeopleModalIsOpen
    }, function () {
      this.props.fetchPeople();
    });
  }

  render() {
    return (
      <div>
        <PageHeader>
          <Row>
            <Col md='8'>
              <h1>People</h1>
            </Col>
            <Col md='4'>
              <AdminOnly>
                <Button onClick={this.toggleAddPeopleModal} color='primary'>Add People</Button>
              </AdminOnly>
            </Col>
          </Row>
        </PageHeader>
        <PeopleTable
          people={this.props.people} />
        <AdminOnly>
          <AddPeopleModal toggle={this.toggleAddPeopleModal} isOpen={this.state.addPeopleModalIsOpen} />
        </AdminOnly>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    people: state.people.peopleData,
    fetching: state.people.fetching,
    fetched: state.people.fetched
  };
}

export default connect(mapStateToProps, { fetchPeople, createInfoAlert, createErrorAlert })(PeopleContainer);

