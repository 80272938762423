import React, { Component, useEffect } from 'react';
import {Line} from 'react-chartjs-2';
import propTypes from 'prop-types';

export default class LineGraph extends Component {
    static propTypes = {
      months: propTypes.array.isRequired,
      data: propTypes.array.isRequired,
      yaxisMin: propTypes.number.isRequired,
      yaxisMax: propTypes.number.isRequired
    };
  
    render() {
        const data = {
            labels: this.props.months,
            datasets: [
              {
                fill: true,
                lineTension: 0.3,
                backgroundColor: 'rgba(29, 88, 141,.6)',
                borderColor: 'rgba(170, 250, 170,1)',
                borderDashOffset: 0.0,
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 3,
                pointHitRadius: 10,
                data: this.props.data
              }
            ]
          };

        return (
            <div>
                <Line 
                  data={data} 
                  legend={{ display: false }}  
                  options={{ maintainAspectRatio: false, scales: 
                    {
                      xAxes: [
                        { 
                          gridLines: { 
                            drawOnChartArea: false
                          }
                        }],
                      yAxes: [
                        {
                          ticks: {
                            stepSize: 20,
                            suggestedMin: this.props.yaxisMin,
                            suggestedMax: this.props.yaxisMax
                          },
                          gridLines: {
                            drawOnChartArea: true,
                          }
                        }
                      ]
                    }
                  }}
                />
            </div>
        )
    }
}