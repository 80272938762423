import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { addPerson, fetchPeople } from '../../../actions/people/peopleActions';
import StatesCombobox from '../../common/form_helpers/statesCombobox';

class AddPersonForm extends Component {
    static propTypes = {
        church_id: PropTypes.number,
        dismissModalFunc: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            middle_name: '',
            primary_phone: '',
            secondary_phone: '',
            birthdate: '',
            email: '',
            city: '',
            street: '',
            zip: '',
            state: '',
            country: '',
            church_id: this.props.church_id
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            [target.name]: value
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const context = this;

        addPerson(this.state)
            .then(function(response){
                if(context.props.dismissModalFunc) {
                    context.props.dismissModalFunc();
                }
            });
    }

    renderForm = () => {
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup row>
                    <Label md={2}>First Name*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='first_name' type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Middle Name</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='middle_name' type='text' /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Last Name*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='last_name' type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Primary Phone*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='primary_phone' type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Secondary Phone</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='secondary_phone' type='text' /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Birthdate*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='birthdate' type='date' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Email*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='email' type='email' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>City*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='city' type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Street*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='street' type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Zip Code*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='zip' type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>State</Label>
                    <Col md={10}> <StatesCombobox handleStateChoice={this.handleChange} /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Country*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='country' type='text' required /> </Col>
                </FormGroup>
                <Row>
                    <Col md='10' />
                    <Col md='2'>
                        <Button color='primary'>Submit</Button>
                    </Col>
                </Row>
            </Form>
        )
    }

    render() {
        return this.renderForm();
    }
}

const mapStateToProps = (state) => {
    return {
        church_id: state.auth.church_id
    };
}

export default connect(mapStateToProps)(AddPersonForm);