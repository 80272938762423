import React, { Component } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import AddPersonForm from '../forms/addPersonForm';
import AddPeopleExcelForm from '../forms/addPeopleExcelForm';

export default class AddPeopleModal extends Component {
    
    static propTypes = {
        isOpen: PropTypes.bool,
        toggle: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            currentTab: '1'
        };
    }

    toggleTab = (tab) => {
        if(this.state.currentTab !== tab) {
            this.setState({
                currentTab: tab
            });
        }
    }

    isActive = (tab) => {
        return this.state.currentTab === tab ? 'active' : '';
    }

    render() {

        return (
            <div>
                <Modal size= 'lg' isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}>Add People</ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={this.isActive('1')} onClick={() => { this.toggleTab('1'); }} >
                                Via Form
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={this.isActive('2')} onClick={() => { this.toggleTab('2'); }} >
                                Via Spreadsheet
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.currentTab}>
                    <br /><br />
                        <TabPane tabId="1">
                            <AddPersonForm dismissModalFunc={this.props.toggle} />
                        </TabPane>

                        <TabPane tabId="2">
                            <AddPeopleExcelForm dismissModalFunc={this.props.toggle} />
                        </TabPane>
                    </TabContent>                
                </ModalBody>
                </Modal>
            </div>
        )
    }
}