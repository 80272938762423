import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../pageHeader';
import { Button, Row, Col } from 'reactstrap';
import { fetchPeople } from '../../../actions/people/peopleActions';
import { connect } from 'react-redux';
import PeopleTable from '../PeopleTable';

class ManagePeopleSmartContainer extends Component {
    static propTypes = {
        people: PropTypes.array,
        fetchPeople: PropTypes.func
    };
    
    componentDidMount() {
        this.props.fetchPeople();
    }

    render() {
        return (
            <div>
                <PageHeader>
                    <Row>
                        <Col md='8'>
                            <h1>Manage People</h1>
                        </Col>
                        <Col md='4'>
                            <Button>Action Button</Button>
                        </Col>
                    </Row>
                </PageHeader>
                <br />
                <PeopleTable people={this.props.people} />
            </div>
        )
    }
}

const MapStateToProps = (state) => {
    return {
        people: state.people.peopleData
    };
}
export default connect(MapStateToProps, { fetchPeople })(ManagePeopleSmartContainer);