import React, { Component } from 'react';
import PropTypes from 'prop-types';
import unknown_user_logo from '../../../images/coptic_cross.png';

export default class UserSidebarProfilePic extends Component {

    static propTypes = {
        link: PropTypes.string
    };

    renderUserPic = () => {
        
        const link = this.props.link ? this.props.link : unknown_user_logo;

        return (
            <img 
                width='100'
                height='100'
                className='sidebar-profile-pic'
                src={link} />

        );
    }

    render() {
        return this.renderUserPic();
    }
}