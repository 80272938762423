import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Grid, TextField, MenuItem, Stepper, Step, StepLabel, Paper, Checkbox, FormControlLabel } from '@material-ui/core';
import { KeyboardDatePicker, DatePicker } from '@material-ui/pickers';
import PageHeader from '../../pageHeader';
import AddStudentFormSection from './addStudentFormSection';
import { getChurches } from '../../../actions/churches/churchActions';
import Countries from '../../common/data/countries';
import States from '../../common/data/states';
import * as Yup from 'yup';
import { registerStudents } from '../../../actions/people/peopleActions';
import { createInfoAlert } from '../../../actions/alerts/alertActions';
import { Redirect } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import MaskedPhoneNumberInput from '../../common/form_helpers/maskedPhoneNumberInput';

const MyInput = ({ validationFieldParent, validationField, field, form, ...rest }) => {
  const style = {
    error: {
      color: 'red',
      background: '#eee'
    }
  };

  const placeholderText = rest.hasOwnProperty('required')
    && rest.hasOwnProperty('placeholder')
    ? `${rest.placeholder}*`
    : rest.placeholder;

  if (rest.hasOwnProperty('placeholder') && placeholderText !== rest.placeholder) {
    delete rest.placeholder;
  }
  return (
    <div>

      <TextField fullWidth={true} variant="outlined" placeholder={placeholderText} {...field} {...rest} />
      {form.errors[validationFieldParent] &&
        form.errors[validationFieldParent][validationField] &&
        form.touched[validationFieldParent] &&
        form.touched[validationFieldParent][validationField] &&
        <p style={style.error}>{form.errors[validationFieldParent][validationField]}</p>}
      <br />
    </div>
  )
}

const FieldArrayInput = ({ validationFieldParent, validationIndex, validationField, field, form, ...rest }) => {
  const style = {
    error: {
      color: 'red',
      background: '#eee'
    }
  };

  const placeholderText = rest.hasOwnProperty('required')
    && rest.hasOwnProperty('placeholder')
    ? `${rest.placeholder}*`
    : rest.placeholder;

  if (rest.hasOwnProperty('placeholder') && placeholderText !== rest.placeholder) {
    delete rest.placeholder;
  }

  return (
    <div>
      <TextField fullWidth={true} variant="outlined" placeholder={placeholderText} {...field} {...rest} />
      {form.errors[validationFieldParent] &&
        form.errors[validationFieldParent][validationIndex] &&
        form.errors[validationFieldParent][validationIndex][validationField] &&
        form.touched[validationFieldParent] &&
        form.touched[validationFieldParent][validationIndex] &&
        form.touched[validationFieldParent][validationIndex][validationField] &&
        <p style={style.error}>{form.errors[validationFieldParent][validationIndex][validationField]}</p>}
      <br />
    </div>
  )
}

const Next = ({ ...props }) =>
  <Button color='primary' {...props} ></Button>

const Previous = ({ ...props }) =>
  <Button {...props}></Button>


const initialAdultStudentValues = {
  student: {
    first_name: '',
    last_name: '',
    middle_name: '',
    primary_phone: '',
    secondary_phone: '',
    birthdate: '',
    email: '',
    city: '',
    street: '',
    zip: '',
    state: '',
    country: '',
    church_id: ""
  }
};

const emptyPersonObject = {
  first_name: '',
  last_name: '',
  middle_name: '',
  primary_phone: '',
  secondary_phone: '',
  birthdate: '',
  email: '',
  city: '',
  street: '',
  zip: '',
  state: '',
  country: '',
  church_id: ""
}

const initialChildStudentValues = {
  parent: {
    first_name: '',
    last_name: '',
    middle_name: '',
    primary_phone: '',
    secondary_phone: '',
    birthdate: '',
    email: '',
    city: '',
    street: '',
    zip: '',
    state: '',
    country: '',
    church_id: ""
  },
  minorStudents: [
    {
      first_name: '',
      last_name: '',
      middle_name: '',
      primary_phone: '',
      secondary_phone: '',
      birthdate: '',
      email: '',
      city: '',
      street: '',
      zip: '',
      state: '',
      country: '',
      church_id: "",
      sameAddressAsGuardianCheckBox: false
    },
  ],
};

const personValidation = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required.'),
  primary_phone: Yup.string().required('Primary Phone is required.').matches(/^\(\d{3}\)-\d{3}-\d{4}$/, 'Phone Number must be in (555)-555-5555 format.'),
  birthdate: Yup.string().required('Birthdate is required').matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in YYYY-MM-DD format.'),
  email: Yup.string().required('Email is required.').email('Email is not valid'),
  city: Yup.string().required('City is required.'),
  street: Yup.string().required('Street address is required.'),
  //zip: Yup.string().matches(/^\d{5}$/, 'Zip Code must be 5 digits'),
  country: Yup.string().required('Country selection is required.'),
  church_id: Yup.string().required('Church selection is required.')
});

const adultStudentValidationSchema = Yup.object().shape({
  student: personValidation
});

const childStudentValidationSchema = Yup.object().shape({
  parent: personValidation,
  minorStudents: Yup.array().of(
    personValidation
  )
});

export default class RegisterStudentForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAdult: false,
      choseAdultOrMinor: false,
      churches: [],
      successfullyRegistered: false,
      formStep: 0
    };
  }

  componentDidMount() {
    const _this = this;

    getChurches().then(function (response) {
      _this.setState({ ..._this.state, churches: response.data });
      console.log('getChurches', response.data);
    });
  }
  renderForm = () => {

  }

  handleStudentRegistration = (data) => {
    const _this = this;

    registerStudents(data).then(function (response) {
      _this.setState({ ..._this.state, successfullyRegistered: true });
    });
  }

  areCurrentStepFieldsValid = (errors, touched, formFieldComponents) => {
    let valid = true;

    //console.log("ERRORS::::::::::; ", errors);
    //console.log("formFieldComponents::::::::::: ", formFieldComponents);
    //console.log("TOUCHED::::::::::::::: ", touched);

    const fields = this.returnFieldNamesFromComponentTree(formFieldComponents);
    //console.log("RETURN OF FUNCTION:::::::: ", fields);
    fields.forEach((field) => {
      const [section, property] = field.name.split('.');
      //console.log(`section=${section} property=${property}`);
      if (field.required && (!(section in touched) || (section in touched) && !(property in touched[section]))) {
        //console.log(`${section}.${property} is required.`);
        //console.log(`${field.name} was found to be not touched and required so valid is marked false`);
        valid = false;
      }

      if (field.required && ((section in errors) && (property in errors[section]))) {
        //console.log(`${field.name} was found to be have an error and is marked false`);
        valid = false;
      }
    });

    //console.log("VALID????????::: ", valid);

    return valid;
  }

  returnFieldNamesFromComponentTree = (formFieldComponents) => this.returnFieldNamesFromComponentTreeRecursiveCall(formFieldComponents, []);
  returnFieldNamesFromComponentTreeRecursiveCall = (formFieldComponents, arrayToReturn) => {
    const _this = this;

    if (formFieldComponents.props !== undefined && formFieldComponents.props.children !== undefined && Array.isArray(formFieldComponents.props.children)) {
      formFieldComponents.props.children.forEach((formField) => {
        _this.returnFieldNamesFromComponentTreeRecursiveCall(formField, arrayToReturn);
      });
    } else if (formFieldComponents.props !== undefined && formFieldComponents.props.children !== undefined && !Array.isArray(formFieldComponents.props.children)
      && formFieldComponents.props.children.props !== undefined && formFieldComponents.props.children.props.name !== undefined) {
      //console.log(formFieldComponents.props.children.props.name);
      arrayToReturn.push({ name: formFieldComponents.props.children.props.name, required: formFieldComponents.props.children.props.required });
    }

    return arrayToReturn;
  }

  renderAdultStudentFormPart = () => {
    const index = 3333;
    const _this = this;
    const style = {
      error: {
        color: 'red',
        background: '#eee'
      }
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialAdultStudentValues}
        validationSchema={adultStudentValidationSchema}
        onSubmit={(values, errors) => {
          _this.handleStudentRegistration(values);
          console.log(JSON.stringify(values, null, 2));
        }}
        render={({ values, errors, touched }) => (
          <Form>
            <Grid container>
              <Grid item>
                <label>First Name*</label>
                <Field
                  name={`student.first_name`}
                  component={MyInput}
                  placeholder="First Name"
                  type="text"
                  validationFieldParent='student'
                  validationField='first_name'
                />
              </Grid>

              <Grid item>
                <label>Middle Name</label>
                <Field
                  name={`student.middle_name`}
                  component={MyInput}
                  placeholder="Middle Name"
                  type="text"
                />
              </Grid>

              <Grid item>
                <label>Last Name*</label>
                <Field
                  name={`student.last_name`}
                  component={MyInput}
                  placeholder="Last Name"
                  type="text"
                  validationFieldParent="student"
                  validationField="last_name"
                />
              </Grid>

              <Grid item>
                <label>Primary Phone*</label>
                <Field
                  name={`student.primary_phone`}
                  component={MyInput}
                  placeholder="Primary Phone"
                  type="text"
                  validationFieldParent="student"
                  validationField="primary_phone"
                />
              </Grid>

              <Grid item>
                <label>Secondary Phone</label>
                <Field
                  name={`student.secondary_phone`}
                  component={MyInput}
                  placeholder="Secondary Phone"
                  type="text"
                />
              </Grid>

              <Grid item>
                <label>Birthdate*</label>
                <Field
                  name={`student.birthdate`}
                  component={MyInput}
                  placeholder="Birthdate (YYYY-MM-DD)"
                  type="text"
                  validationFieldParent="student"
                  validationField="birthdate"
                />
              </Grid>

              <Grid item>
                <label>Email*</label>
                <Field
                  name={`student.email`}
                  component={MyInput}
                  placeholder="Email"
                  type="email"
                  validationFieldParent="student"
                  validationField="email"
                />
              </Grid>

              <Grid item>
                <label>Street*</label>
                <Field
                  name={`student.street`}
                  component={MyInput}
                  placeholder="Street"
                  type="text"
                  validationFieldParent="student"
                  validationField="street"
                />
              </Grid>

              <Grid item>
                <label>City*</label>
                <Field
                  name={`student.city`}
                  component={MyInput}
                  placeholder="City"
                  type="text"
                  validationFieldParent="student"
                  validationField="city"
                />
              </Grid>

              <Grid item>
                <label>Zip</label>
                <Field
                  name={`student.zip`}
                  component={MyInput}
                  placeholder="Zip Code"
                  type="text"
                  validationFieldParent="student"
                  validationField="zip"
                />
              </Grid>

              <Grid item>
                <label>State</label>
                <Field
                  name={`student.state`}
                  component={MyInput}
                  placeholder="State"
                  select
                >
                  <MenuItem value=''>Select a state</MenuItem>
                  {
                    Object.keys(States).map(function (abbr, index) {
                      return <MenuItem key={index} value={abbr}>{States[abbr]}</MenuItem>;
                    })
                  }
                </Field>
              </Grid>

              <Grid item>
                <label>Country*</label>
                <Field
                  name={`student.country`}
                  component={MyInput}
                  placeholder="Country"
                  select
                  validationFieldParent="student"
                  validationField="country"
                >
                  <MenuItem value=''>Select a country</MenuItem>
                  {
                    Countries.map(function (country, index) {
                      return <MenuItem key={index} value={country}>{country}</MenuItem>;
                    })
                  }
                </Field>
              </Grid>

              <Grid item>
                <label>Church*</label>
                <br />
                <Field component={MyInput}
                  name='student.church_id'
                  select
                  validationFieldParent="student"
                  validationField="church_id"
                >
                  <MenuItem value=''>Select a church</MenuItem>
                  {
                    this.state.churches.map(function (church, index) {
                      return <MenuItem key={church.id} value={church.id}>{church.name} - {church.city}, {church.state ? church.state + ', ' : ''} {church.country}</MenuItem>;
                    })
                  }
                </Field>
              </Grid>
            </Grid>
            <br />
            <Button color='primary' type='submit'>Submit</Button>
          </Form>
        )}
      />
    )
  }

  renderParentInformation = (values, setFieldValue, setFieldTouched) => {
    return (
      <Grid container spacing={2}>

        <Grid item md={12}>
          <br />
          <h3>Parent/Guardian Information</h3>
          <hr />
          <br />
        </Grid>

        <Grid item md={4}>
          <Field
            name={`parent.first_name`}
            component={MyInput}
            type="text"
            placeholder="First Name"
            validationFieldParent='parent'
            validationField='first_name'
            required
          />
        </Grid>

        <Grid item md={4}>
          <Field
            name={`parent.middle_name`}
            component={MyInput}
            placeholder="Middle Name"
            type="text"
          />
        </Grid>

        <Grid item md={4}>
          <Field
            name={`parent.last_name`}
            component={MyInput}
            placeholder="Last Name"
            type="text"
            validationFieldParent="parent"
            validationField="last_name"
            required
          />
        </Grid>

        <Grid item md={6}>
          <MaskedPhoneNumberInput onChangedValue={(number) => setFieldValue('parent.primary_phone', number)}>
            <Field
              name={`parent.primary_phone`}
              component={MyInput}
              placeholder="Primary Phone"
              type="text"
              validationFieldParent="parent"
              validationField="primary_phone"
              label='Primary Phone'
              required
            />
          </MaskedPhoneNumberInput>
        </Grid>

        <Grid item md={6}>
          <MaskedPhoneNumberInput onChangedValue={(number) => setFieldValue('parent.secondary_phone', number)}>
            <Field
              name={`parent.secondary_phone`}
              component={MyInput}
              placeholder="Secondary Phone"
              type="text"
              validationFieldParent="parent"
              validationField="secondary_phone"
              label='Secondary Phone'
            />
          </MaskedPhoneNumberInput>
        </Grid>

        <Grid item md={12}>
          <Field
            name={`parent.birthdate`}
            component={DatePicker}
            label="Birthdate"
            placeholder="Birthdate (YYYY-MM-DD)"
            inputVariant="outlined"
            format="yyyy-MM-DD"
            value={values.parent.birthdate ? values.parent.birthdate : null}
            validationFieldParent="parent"
            validationField="birthdate"
            fullWidth
            autoOk
            onChange={(date) => { setFieldTouched('parent.birthdate', true); setFieldValue('parent.birthdate', `${date.format('yyyy-MM-DD')}`) }}
            required
          />
        </Grid>

        <Grid item md={12}>
          <Field
            name={`parent.email`}
            component={MyInput}
            placeholder="Email"
            type="email"
            validationFieldParent="parent"
            validationField="email"
            required
          />
        </Grid>

        <Grid item md={5}>
          <Field
            name={`parent.street`}
            component={MyInput}
            placeholder="Street"
            type="text"
            validationFieldParent="parent"
            validationField="street"
            required
          />
        </Grid>

        <Grid item md={4}>
          <Field
            name={`parent.city`}
            component={MyInput}
            placeholder="City"
            type="text"
            validationFieldParent="parent"
            validationField="city"
            required
          />
        </Grid>

        <Grid item md={3}>
          <Field
            name={`parent.zip`}
            component={MyInput}
            placeholder="Zip Code"
            type="text"
            validationFieldParent="parent"
            validationField="zip"
          />
        </Grid>

        <Grid item md={6}>
          <Field
            name={`parent.state`}
            component={MyInput}
            placeholder="State"
            label="State"
            select
          >
            <MenuItem value=''>Select a state</MenuItem>
            {
              Object.keys(States).map(function (abbr, index) {
                return <MenuItem key={index} value={abbr}>{States[abbr]}</MenuItem>;
              })
            }
          </Field>
        </Grid>

        <Grid item md={6}>
          <Field
            name={`parent.country`}
            component={MyInput}
            placeholder="Country"
            select
            validationFieldParent="parent"
            validationField="country"
            label="Country"
            required
          >
            <MenuItem value=''>Select a country</MenuItem>
            {
              Countries.map(function (country, index) {
                return <MenuItem key={index} value={country}>{country}</MenuItem>;
              })
            }
          </Field>
        </Grid>

        <Grid item md={12}>
          <br />
          <Field component={MyInput}
            name='parent.church_id'
            select
            validationFieldParent="parent"
            validationField="church_id"
            label="Church"
            required
          >
            <MenuItem value=''>Select a church</MenuItem>
            {
              this.state.churches.map(function (church, index) {
                return <MenuItem key={church.id} value={church.id}>{church.name} - {church.city}, {church.state ? church.state + ', ' : ''} {church.country}</MenuItem>;
              })
            }
          </Field>
        </Grid>
      </Grid>
    )
  }

  renderChildInformation = (values, setFieldValue, setFieldTouched) => {
    return (
      <FieldArray
        name="minorStudents"
        render={({ insert, remove, push }) => (
          <div>
            {values.minorStudents.length > 0 &&
              values.minorStudents.map((student, index) => (
                <div key={index}>
                  <br />
                  <h3>Child {index + 1}</h3>
                  <hr />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <Field
                        name={`minorStudents.${index}.first_name`}
                        component={FieldArrayInput}
                        placeholder="First Name"
                        type="text"
                        validationFieldParent='minorStudents'
                        validationField='first_name'
                        validationIndex={index}
                        required
                      />
                    </Grid>

                    <Grid item md={4}>
                      <Field
                        name={`minorStudents.${index}.middle_name`}
                        component={MyInput}
                        placeholder="Middle Name"
                        type="text"
                      />
                    </Grid>

                    <Grid item md={4}>
                      <Field
                        name={`minorStudents.${index}.last_name`}
                        component={FieldArrayInput}
                        placeholder="Last Name"
                        type="text"
                        validationFieldParent='minorStudents'
                        validationField='last_name'
                        validationIndex={index}
                        required
                      />
                    </Grid>

                    <Grid item md={6}>
                      <MaskedPhoneNumberInput onChangedValue={(number) => setFieldValue(`minorStudents.${index}.primary_phone`, number)}>
                        <Field
                          name={`minorStudents.${index}.primary_phone`}
                          component={FieldArrayInput}
                          placeholder="Primary Phone"
                          type="text"
                          validationFieldParent='minorStudents'
                          validationField='primary_phone'
                          validationIndex={index}
                          required
                        />
                      </MaskedPhoneNumberInput>
                    </Grid>

                    <Grid item md={6}>
                      <MaskedPhoneNumberInput onChangedValue={(number) => setFieldValue(`minorStudents.${index}.secondary_phone`, number)}>
                        <Field
                          name={`minorStudents.${index}.secondary_phone`}
                          component={FieldArrayInput}
                          placeholder="Secondary Phone"
                          type="text"
                          validationFieldParent='minorStudents'
                          validationField='secondary_phone'
                          validationIndex={index}
                        />
                      </MaskedPhoneNumberInput>
                    </Grid>

                    <Grid item md={12}>
                      <Grid item md={12}>
                        <Field
                          name={`minorStudents.${index}.birthdate`}
                          component={DatePicker}
                          label="Birthdate"
                          placeholder="Birthdate (YYYY-MM-DD)"
                          inputVariant="outlined"
                          format="yyyy-MM-DD"
                          value={values.minorStudents[index].birthdate ? values.minorStudents[index].birthdate : null}
                          validationFieldParent="minorStudents"
                          validationField="birthdate"
                          fullWidth
                          autoOk
                          onChange={(date) => { setFieldTouched(`minorStudents.${index}.birthdate`, true); setFieldValue(`minorStudents.${index}.birthdate`, `${date.format('yyyy-MM-DD')}`) }}
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid item md={12}>
                      <Field
                        name={`minorStudents.${index}.email`}
                        component={FieldArrayInput}
                        placeholder="Email"
                        type="email"
                        validationFieldParent='minorStudents'
                        validationField='email'
                        validationIndex={index}
                        required
                      />
                    </Grid>

                    <Grid item md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.minorStudents[index].sameAddressAsGuardianCheckBox}
                            onChange={() => {
                              setFieldValue(
                                `minorStudents.${index}.sameAddressAsGuardianCheckBox`,
                                !values.minorStudents[index].sameAddressAsGuardianCheckBox
                              );

                              if (values.minorStudents[index].sameAddressAsGuardianCheckBox === false ||
                                !("sameAddressAsGuardianCheckBox" in values.minorStudents[index])
                              ) {

                                setFieldValue(
                                  `minorStudents.${index}.street`,
                                  values.parent.street
                                );
                                setFieldValue(
                                  `minorStudents.${index}.city`,
                                  values.parent.city
                                );
                                setFieldValue(
                                  `minorStudents.${index}.zip`,
                                  values.parent.zip
                                );
                                setFieldValue(
                                  `minorStudents.${index}.state`,
                                  values.parent.state
                                );
                                setFieldValue(
                                  `minorStudents.${index}.country`,
                                  values.parent.country
                                );
                                setFieldValue(
                                  `minorStudents.${index}.church_id`,
                                  values.parent.church_id
                                );
                              }
                            }}
                            color="primary"
                          />
                        }
                        label="Use same address and church as Parent/Guardian"
                      />
                    </Grid>

                    <Grid item md={5}>
                      <Field
                        name={`minorStudents.${index}.street`}
                        component={FieldArrayInput}
                        placeholder="Street"
                        type="text"
                        validationFieldParent='minorStudents'
                        validationField='street'
                        validationIndex={index}
                        required
                        disabled={values.minorStudents[index].sameAddressAsGuardianCheckBox}
                      />
                    </Grid>

                    <Grid item md={4}>
                      <Field
                        name={`minorStudents.${index}.city`}
                        component={FieldArrayInput}
                        placeholder="City"
                        type="text"
                        validationFieldParent='minorStudents'
                        validationField='city'
                        validationIndex={index}
                        required
                        disabled={values.minorStudents[index].sameAddressAsGuardianCheckBox}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <Field
                        name={`minorStudents.${index}.zip`}
                        component={FieldArrayInput}
                        placeholder="Zip Code"
                        type="text"
                        validationFieldParent='minorStudents'
                        validationField='zip'
                        validationIndex={index}
                        disabled={values.minorStudents[index].sameAddressAsGuardianCheckBox}
                      />
                    </Grid>

                    <Grid item md={6}>
                      <Field
                        name={`minorStudents.${index}.state`}
                        component={MyInput}
                        placeholder="State"
                        label="State"
                        select
                        disabled={values.minorStudents[index].sameAddressAsGuardianCheckBox}
                      >
                        <MenuItem value=''>Select a state</MenuItem>
                        {
                          Object.keys(States).map(function (abbr, index) {
                            return <MenuItem key={index} value={abbr}>{States[abbr]}</MenuItem>;
                          })
                        }
                      </Field>
                    </Grid>

                    <Grid item md={6}>
                      <Field
                        name={`minorStudents.${index}.country`}
                        component={FieldArrayInput}
                        placeholder="Country"
                        select
                        validationFieldParent='minorStudents'
                        validationField='country'
                        label="Country"
                        validationIndex={index}
                        required
                        disabled={values.minorStudents[index].sameAddressAsGuardianCheckBox}
                      >
                        <MenuItem value=''>Select a country</MenuItem>
                        {
                          Countries.map(function (country, index) {
                            return <MenuItem key={index} value={country}>{country}</MenuItem>;
                          })
                        }
                      </Field>
                    </Grid>

                    <Grid item md={12}>
                      <br />
                      <Field component={FieldArrayInput} name={`minorStudents.${index}.church_id`} select
                        validationFieldParent='minorStudents'
                        validationField='church_id'
                        validationIndex={index}
                        label="Church"
                        required
                        disabled={values.minorStudents[index].sameAddressAsGuardianCheckBox}
                      >
                        <MenuItem>Select a church</MenuItem>
                        {
                          this.state.churches.map(function (church) {
                            return <MenuItem key={church.id} value={church.id}>{church.name} - {church.city}, {church.state ? church.state + ', ' : ''} {church.country}</MenuItem>;
                          })
                        }
                      </Field>
                    </Grid>
                    <Grid item>
                      {values.minorStudents.length > 1 &&
                        <Button
                          color='danger'
                          onClick={() => remove(index)}
                        >
                          Remove Child
                </Button>
                      }
                    </Grid>
                  </Grid>
                  <br /><br />
                </div>
              ))}
            <a
              href="javascript:void(0)"
              onClick={() => push({
                first_name: '',
                last_name: '',
                middle_name: '',
                primary_phone: '',
                secondary_phone: '',
                birthdate: '',
                email: '',
                city: '',
                street: '',
                zip: '',
                state: '',
                country: '',
                church_id: ""
              })}
            >
              <h4>+ Add Child</h4><br />
            </a>
          </div>
        )}
      />
    )
  }

  renderChildRegistrationForm = () => {
    let fieldArray = initialChildStudentValues.minorStudents;
    const emptyFieldArray = Object.create(emptyPersonObject);
    const _this = this;
    const { formStep } = this.state;

    const formStepsInfo = [
      {
        label: "Guardian Information",
        renderStep: (values, setFieldValue, setFieldTouched) => this.renderParentInformation(values, setFieldValue, setFieldTouched)
      },
      {
        label: "Child Information",
        renderStep: (values, setFieldValue, setFieldTouched) => this.renderChildInformation(values, setFieldValue, setFieldTouched)
      }
    ];

    return (
      <>
        <Stepper activeStep={formStep} alternativeLabel>
          {formStepsInfo.map((step, index) => {
            return (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <br />
        <Formik
          enableReinitialize={true}
          initialValues={initialChildStudentValues}
          validationSchema={childStudentValidationSchema}
          onSubmit={(values, errors) => {
            _this.handleStudentRegistration(values);
            console.log(JSON.stringify(values, null, 2));
          }}
          render={({ values, errors, touched, setFieldValue, setFieldTouched }) => (
            <Form>
              <Grid container>

                <Grid item md={2}></Grid>
                <Grid item md={8}>
                  <Paper style={{ "padding": "40px" }} elevation={2}>
                    {
                      formStepsInfo[formStep].renderStep(values, setFieldValue, setFieldTouched)
                    }
                    <br /><br />

                    <Grid container>
                      <Grid item md={1}>
                        <Button
                          color='primary'
                          onClick={() => this.previousFormStep()}
                          hidden={formStep <= 0}>
                          Previous
                        </Button>
                      </Grid>
                      <Grid item md={10}></Grid>
                      <Grid item md={1}>
                        <Button color='primary'
                          onClick={() => { this.nextFormStep(); }}
                          disabled={!this.areCurrentStepFieldsValid(errors, touched, formStepsInfo[formStep].renderStep(values, setFieldValue, setFieldTouched)) || formStep >= formStepsInfo.length - 1}
                          hidden={formStep === formStepsInfo.length - 1}>
                          Next
                        </Button>
                        <Button
                          color='success'
                          hidden={formStep !== formStepsInfo.length - 1}
                          disabled={!this.areCurrentStepFieldsValid(errors, touched, formStepsInfo[formStep].renderStep(values, setFieldValue, setFieldTouched))}
                          type='submit'>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>

                  </Paper>
                </Grid>
              </Grid>
              <Grid item md={2}></Grid>
            </Form >
          )
          }
        />
      </>
    )
  }

  nextFormStep = () => this.setState({ formStep: this.state.formStep + 1 });
  previousFormStep = () => this.setState({ formStep: this.state.formStep - 1 });

  handleStudentAge = (isAdult) => {
    this.setState({ ...this.state, isAdult: isAdult, choseAdultOrMinor: true });
  }

  render() {
    const { isAdult, choseAdultOrMinor, churches, successfullyRegistered } = this.state;

    return (
      <div>
        <PageHeader>
          <h3>Student Registration</h3>
        </PageHeader>
        <br />

        {this.state.successfullyRegistered &&
          <div>
            <h5 style={{ color: 'green' }}>Thank you for registering. Your church administrator has received your registration. You may now close this page.</h5>
          </div>
        }


        {!choseAdultOrMinor &&
          !successfullyRegistered &&
          <div>
            <h3>The student being registered is</h3>
            <Button color='primary' onClick={() => this.handleStudentAge(false)}>Under 18</Button>
                      &nbsp;
                      <Button color='primary' onClick={() => this.handleStudentAge(true)}>18 or Above</Button>
          </div>
        }


        {isAdult && choseAdultOrMinor &&
          !successfullyRegistered &&
          <div>
            {this.renderAdultStudentFormPart()}
          </div>
        }

        {!isAdult && choseAdultOrMinor && !successfullyRegistered &&
          <div>
            {this.renderChildRegistrationForm()}
          </div>
        }
      </div>
    )
  }
}