import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import LoginForm from '../../components/auth/loginForm';
import { login, validateToken } from '../../actions/auth/authActions';
import PageHeader from '../../components/pageHeader';

class LoginContainer extends Component {
  loggedIn = () => {
    return (
      <div>
        <center>
          <h1>
            Welcome back {this.props.first_name}, you are logged in.
          </h1>
          <h4>Your login details:</h4>
          <p>Access Token: {this.props.access_token}</p>
          <p>Client Token: {this.props.client_token}</p>
        </center>
      </div>
    )
  }

  render() {
    const isLoggedIn = this.props.isLoggedIn;
    const loginFormRender = <LoginForm loginFunc={this.props.login} />

    return (
      <div>
        <PageHeader>
          <h1>Login</h1>
        </PageHeader>

        {isLoggedIn ? this.props.history.push('/') : loginFormRender}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.logged_in,
    access_token: state.auth.access_token,
    client_token: state.auth.client_token,
    uid: state.auth.uid,
    first_name: state.auth.first_name
  }
}

export default connect(mapStateToProps, { login, validateToken })(LoginContainer);