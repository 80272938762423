import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export function getClasses() {
    return axios.get(`${API_URL}/classes`);
}

export function getClass(id) {
    return axios.get(`${API_URL}/classes/${id}`);
}

export function getServantsOfClass(id) {
    return axios.get(`${API_URL}/classes/${id}/servants`);
}

export function getStudentsOfClass(id) {
    return axios.get(`${API_URL}/classes/${id}/students`);
}

export function addClass(params) {
    return axios.post(`${API_URL}/classes`, params);
}

export function updateClass(params) {
    return axios.put(`${API_URL}/classes/${params.id}`, params);
}

export function deleteClass(id) {
    return axios.delete(`${API_URL}/classes/${id}`);
}

export function addServantsToClass(servant_ids, class_id) {
    return axios.post(`${API_URL}/classes/${class_id}/servants`, {servant_ids: servant_ids});
}
  
export function addStudentsToClass(student_ids, class_id) {
    return axios.post(`${API_URL}/classes/${class_id}/students`, {student_ids: student_ids});
}

export function removeServantFromClass(servant_id, class_id) {
    return axios.delete(`${API_URL}/classes/${class_id}/servants/${servant_id}`);
}

export function removeStudentFromClass(student_id, class_id) {
    return axios.delete(`${API_URL}/classes/${class_id}/students/${student_id}`);
}