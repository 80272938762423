import React, { useEffect, useState } from 'react';
import LineGraph from '../lineGraph';
import { Button, List, MenuItem, Link, Typography } from '@material-ui/core';
import { getAttendanceData } from '../../../actions/dashboard/statisticActions';

/*

    data structure:
    [{
        class_name: 'High School',
        attendances_by_month: [{
            month: 'February',
            presences: 34,
            excused: 14,
            absences: '50'
        }]
    }]

*/

function getMonths(data) {
    const MONTHS = ['January', 'February', 
        'March', 'April', 'May', 'June', 'July', 
        'August', 'September', 'October', 'November', 
        'December'];

    return data.map((attendance_by_month) => {
        return MONTHS[attendance_by_month.month - 1];
    });
}

function getDataPoints(data) {
    // data is attendances_by_month
    return data.map((attendance_by_month) => {
        return attendance_by_month.presences;
    });
}

function makeGraphArray(data) {
    return data.map((class_attendance, index) => {
        return {
            id: index,
            class_name: class_attendance.class_name,
            months: getMonths(class_attendance.attendances_by_month),
            data: getDataPoints(class_attendance.attendances_by_month) 
        };
    });
}

function getGraphSelectionOptions(graphArray) {
    return graphArray.map((graph) => {
        return graph.class_name;
    });
}

function renderGraph(graphArrayObject) {
    return <LineGraph months={graphArrayObject.months} data={graphArrayObject.data} redraw />;
}

export default function AttendanceGraph() {
    let [graphArray, setGraphArray] = useState([]);
    let [graphIndex, setGraphIndex] = useState(0);

    useEffect(() => {    
        let mount = true;
        getAttendanceData(1).then(response => {
            if(mount) {
                setGraphArray(makeGraphArray(response.data));
            }
        })

        return () => { mount = false }
    }, [setGraphArray]);

    return (
        <React.Fragment>
            {graphArray.length > 0 && (
                <React.Fragment>
                    <List style={{"display": "inline-flex"}}>
                        {
                            graphArray.map((item, index) => {
                                return (
                                    <MenuItem selected={graphArray[graphIndex].id === index}>
                                        <Link href='' 
                                            onClick={(e) => { e.preventDefault(); setGraphIndex(index); }}
                                        >
                                            {item.class_name}
                                        </Link>
                                    </MenuItem>
                                );
                            })
                        }
                    </List>
                    { graphArray[graphIndex].months.length > 1 ?
                    <LineGraph key={graphIndex} yaxisMin={0} yaxisMax={100} months={graphArray[graphIndex].months} data={graphArray[graphIndex].data} />
                    : <Typography variant='h6'>Not Enough Data for this Class to Chart Yet (Must have more than 1 month of data collected)</Typography>
                    }
                </React.Fragment>
            )
            }
        </React.Fragment>
    )
}