import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class PageHeader extends Component {
  static propTypes = {
    text : PropTypes.string
  };

  render() {
    return (
      <div>
        { this.props.children }
        <hr />
      </div>
    );
  }
}