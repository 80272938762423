import { combineReducers } from 'redux';
import people from './people/peopleReducer';
import auth from './auth/authReducer';
import alerts from './alerts/alertReducer';
import notifications from './notifications/notificationsReducer';

export default {
  people,
  auth,
  alerts,
  notifications
};