import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AdminOnly from '../role_filtering/AdminOnly';
import moment from 'moment-timezone';

export default class PersonInformation extends Component {

    static propTypes = {
        person: PropTypes.array,
    }

    renderPersonInformation = () => {
        const { person } = this.props;
        const birthdate = moment.utc(person.birthdate).format('MMMM D, YYYY');
        const email = person.email ? person.email : 'No email on file.';
        const phone_number = person.primary_phone ? person.primary_phone : 'No phone number on file.';
        const state = person.state ? person.state : 'N/A';
        const zip = person.zip ? person.zip : 'N/A';
        return (
            <div>
                <h1>{`${person.first_name}  ${person.last_name}`}</h1>
                <p><b>Birthdate:</b> {birthdate}</p>
                <p><b>Email:</b> {email}</p>
                <p><b>Phone Number:</b> {phone_number}</p>
                <p><b>Address:</b> {person.street}, {person.city}, {state}, {zip}, {person.country}</p>
            </div>
        )
    }

    render() {
        const { person } = this.props;
        return (
            person !== undefined ? this.renderPersonInformation() : <h1> No Info Here for Person</h1>
        );
    }
}