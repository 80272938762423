import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PersonCard from '../people/PersonCard';
import { Grid } from '@material-ui/core';
import Selectable from './selectable';

export default class SelectablePeopleGrid extends Component {
    static propTypes = {
        getPeople: PropTypes.func,
        returnAttendanceToParent: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            people: [],
            personStatusCollection: [] // structure: [{person_id: x, status: 'Present'}...] 
        };
    }

    returnUpdatedAttendanceToParent = () => {
        let people_attendance = { present_students: [], absent_students: [], excused_students: [] };
        const { personStatusCollection } = this.state;
        console.log('this.state.personStatusCollection: ', this.state.personStatusCollection);

        // For whatever reason the structure of personStatusCollection is [0: {}, 1: {}] | Hopefully you can figure out why in the future
        for (var index in this.state.personStatusCollection) {
            const status = personStatusCollection[index].status;
            const person_id = personStatusCollection[index].person_id;

            if (status === 'Present') {
                people_attendance.present_students.push(person_id);
            } else if (status === 'Absent') {
                people_attendance.absent_students.push(person_id);
            } else if (status === 'Excused') {
                people_attendance.excused_students.push(person_id);
            }
        }

        //console.log('People attendance: ', people_attendance);
        this.props.returnAttendanceToParent(people_attendance);
    }

    handleClick = (person_id) => {

        // this.setState({ selectedPeople: {...this.state.selectedPeople, person_id: !this.state.selectedPeople[person_id] }},
        //     function(){
        //         this.returnUpdatedAttendanceToParent();
        //         console.log(this.state.selectedPeople);
        //     });
    }

    componentDidMount() {
        const context = this;

        this.props.getPeople().then(function (response) {
            context.setState({ people: response.data });

            for (let i = 0; i < context.state.people.length; i++) {
                const person_id = context.state.people[i].id;
                context.setState({ personStatusCollection: [...context.state.personStatusCollection, { person_id: person_id, status: '' }] });
            }
        });
    }

    handlePersonStateChange = (person_id, newStatus) => {
        const { personStatusCollection } = this.state;
        const _this = this;

        const currentPersonIndex = personStatusCollection.findIndex((value) => value.person_id === person_id);

        personStatusCollection.splice(currentPersonIndex, 1);
        personStatusCollection.push({ person_id: person_id, status: newStatus });

        this.setState({ personStatusCollection: personStatusCollection }, () => _this.returnUpdatedAttendanceToParent());
    }

    renderLegend = (states) => {
        // states structure is [{ state: 'Present', color: '#dfd'}...]
        const _this = this;
        return (
            <React.Fragment>
                {
                    states.map((state) => {
                        const backgroundStyle = {
                            height: '20px',
                            width: '20px',
                            backgroundColor: state.color
                        };

                        return (
                            <div style={{ display: 'inline-flex' }}>
                                <div style={backgroundStyle}></div>
                                <p> &nbsp;= {state.state}</p>
                            &nbsp;
                            </div>
                        )
                    })
                }
            </React.Fragment>
        )

    }

    showPeople = () => {
        const style = {
            list: {
                display: 'flex',
                listStyleType: 'none',
                flexWrap: 'wrap'
            },
            listItem: {
                padding: '10px'
            },
            linkStyle: {
                color: 'inherit',
                textDecoration: 'none'
            },
        };

        const states = [
            {
                state: 'Present',
                color: 'green'
            },
            {
                state: 'Absent',
                color: 'red'
            },
            {
                state: 'Excused',
                color: '#c9c935'
            }
        ];

        const context = this;

        return (
            <React.Fragment>
                {this.renderLegend(states)}
                <Grid container spacing={1} style={style.list}>
                    {
                        this.state.people.map(function (person, id) {

                            return (
                                <Grid item md={3} style={{ minWidth: '250px' }} key={person.id} onClick={() => context.handleClick(person.id)}>
                                    <Selectable onStateChange={(status) => context.handlePersonStateChange(person.id, status)} states={states} displayState={true}>
                                        <li style={style.listItem}>
                                            <PersonCard
                                                firstName={person.first_name}
                                                lastName={person.last_name}
                                                photoURL={person.photo_url} />
                                        </li>
                                    </Selectable>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </React.Fragment >
        );
    }

    render() {
        const renderNoPeople = this.state.people.length === 0 ? <h4>No people present to select.</h4> : '';
        return (
            <div>
                {renderNoPeople}
                {this.showPeople()}
            </div>
        )
    }
}