import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

export default class LoginForm extends Component {
  static propTypes = {
    loginFunc: PropTypes.func
  }

  login = (event) => {
    event.preventDefault();

    const email = event.target.email.value;
    const password = event.target.password.value;
    this.props.loginFunc(email, password);
  }

  renderForm() {
    return (
      <div>
        <Form onSubmit={this.login}>
          <FormGroup>
            <Label>Email</Label>
            <Input type="email" name="email" />
          </FormGroup>

          <FormGroup>
            <Label>Password</Label>
            <Input type="password" name="password" />
          </FormGroup>

          <div>
            <Button>Submit</Button><br /><br />
            <Link to='/forgot_password'>Forgot Password?</Link>

          </div>
        </Form>

        <br />
        <br />

        <center>
          <p>Are you a servant but you do not yet have an account? <Link to='/register'>Register as Servant</Link></p>
          <p>Are you a student or parent of a student and need to register? <Link to='/registerStudent'>Register Student</Link></p>
        </center>
      </div >
    )
  }

  render() {
    return (
      this.renderForm()
    )
  }
}