import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export function sendBatchAttendance(attendance, class_id) {
    // attendance structure: {present_students: [1, 2, 3...], absent_students: [1, 2, 3...], excused_students: [1, 2, 3...]}
    const payload = {
        class_id: class_id,
        present_students: attendance.present_students,
        absent_students: attendance.absent_students,
        excused_students: attendance.excused_students
    }

    return axios.post(`${API_URL}/attendances/batch`, payload);
}

export function sendBatchAttendanceForDateTime(attendance, class_id, datetime) {
    const payload = {
        class_id: class_id,
        datetime: datetime,
        present_students: attendance.present_students,
        absent_students: attendance.absent_students,
        excused_students: attendance.excused_students
    }

    return axios.post(`${API_URL}/attendances/batch`, payload);
}

export function getClassAttendancesInOrderedDates(class_id) {
    return axios.get(`${API_URL}/attendances/class/${class_id}/by_date`);
}