import React, { useState, useEffect } from 'react';
import unknown_person_logo from '../../../images/coptic_cross.png';
import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Typography,
    Divider,
    makeStyles
} from '@material-ui/core';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { getUpcomingBirthdays } from '../../../actions/dashboard/statisticActions';
import { useSelector } from 'react-redux';

function BirthdayPeopleList(props) {
    const [birthdays, setBirthdays] = useState([]);
    const authState = useSelector(state => state.auth);
    useEffect(() => {
        let mount = true;
        getUpcomingBirthdays(authState.user_id).then(response => {
            if (mount) {
                setBirthdays(response.data);
            }
        })

        return () => { mount = false };
    }, [setBirthdays])


    return (
        <List>
            {birthdays && birthdays.length > 0 ? birthdays.map((birthday_person) => {
                return (
                    <React.Fragment key={birthday_person.id}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar src={unknown_person_logo} />
                            </ListItemAvatar>
                            <ListItemText>
                                <Link to={`people/${birthday_person.id}`}>
                                    {`${birthday_person.first_name} ${birthday_person.middle_name} ${birthday_person.last_name} - ${moment.utc(birthday_person.birthdate).format('MMMM D')}`}
                                </Link>
                            </ListItemText>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                )
            }) :
                <center><h6>No birthdays this month!</h6></center>}
        </List>
    )
}

export default BirthdayPeopleList;