import React, { Component } from 'react';
import { Button, Col, Row, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { fetchPerson, updatePerson } from '../../actions/person/personActions';
import UpdatePersonForm from '../person/UpdatePersonForm';
import PageHeader from '../pageHeader';

export default class UpdatePerson extends Component {
    /* id of person in this.props.match.params.id */

    constructor(props) {
        super(props);

        this.state = {
            person: {},
            personLoaded: false
        };
    }
    
    componentDidMount() {
        const _this = this;
        const person_id = this.props.match.params.id;

        fetchPerson(person_id)
            .then(function(response) {
                _this.setState({
                    person: response.data
                });
            });
    }

    render() {
        const updateForm = <UpdatePersonForm person={this.state.person} updatePerson={updatePerson} />;
        return (
            <div>
                <PageHeader>
                    <h1>Update Person</h1>
                </PageHeader>
                { this.state.personLoaded || updateForm}
            </div>
        )
    }
}