import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-table/react-table.css';
import '../src/global-styles.css';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Routes from './routes';
import persistor_store from './store';
import { PersistGate } from 'redux-persist/es/integration/react';
import registerServiceWorker from './registerServiceWorker';
import AlertHandler from './AlertHandler';

const { persistor, store } = persistor_store();

ReactDOM.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AlertHandler>
          <Routes />
        </AlertHandler>
      </MuiPickersUtilsProvider>
    </Provider>
  </PersistGate>,
  document.getElementById('root')
);

registerServiceWorker();
