import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Row, Container, Col } from 'reactstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import App from './App';
import AdminOnly from './components/role_filtering/AdminOnly';
import Header from './components/header';
import PeopleContainer from './containers/PeopleContainer';
import LoginContainer from './containers/auth/LoginContainer';
import SidebarNavigationContainer from './containers/navigation/sidebarNavigationContainer';
import PersonContainer from './components/person/smart/PersonContainer';
import DashboardContainer from './containers/dashboard/DashboardContainer';
import ChurchClassesContainer from './components/church_classes/smart/churchClassesContainer';
import AttendanceSmartComponent from './components/attendance/smart/attendanceSmartComponent';
import ChurchClassAttendanceSmartComponent from './components/attendance/smart/churchClassAttendanceSmartComponent';
import ManagePeopleSmartContainer from './components/people/smart/ManagePeopleSmartContainer';
import UpdatePerson from './components/person/UpdatePerson';
import ChurchClassContainer from './components/church_classes/smart/churchClassContainer';
import UpdateChurchClassContainer from './components/church_classes/smart/UpdateChurchClassContainer';
import { validateToken, invalidateLogin } from './actions/auth/authActions';
import ResetPassword from './components/auth/forgot_password/resetPassword';
import ForgotPasswordEmailForm from './components/auth/forgot_password/forgotPasswordEmailForm';
import { createErrorAlert, createInfoAlert } from './actions/alerts/alertActions';
import PendingUsers from './components/users/smart/pendingUsers';
import RegisterUserSmartComponent from './components/auth/registration/smart/registerUserSmartContainer';
import CheckupsSmartComponent from './components/checkups/smart/CheckupsSmartComponent';
import RegisterStudentForm from './components/registration/forms/registerStudentForm';
import SiteLayout from './containers/siteLayout';
class Routes extends Component {

  static propTypes = {
    isLoggedIn: PropTypes.bool,
    access_token: PropTypes.string,
    client_token: PropTypes.string,
    uid: PropTypes.string,
    validateToken: PropTypes.func,
    invalidateLogin: PropTypes.func
  };

  AuthenticatedRoute = ({ component: Component, ...rest }) => {
    //this.validateToken();
    return (
      <Route
        {...rest}
        render={(props) => {
          if (this.props.isLoggedIn === true) {
            return <Component {...props} />
          }
          else
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }} />
    )
  }

  initializeAPIHeaders = () => {
    const context = this;
    axios.defaults.headers.common['access-token'] = this.props.access_token;
    axios.defaults.headers.common['client'] = this.props.client_token;
    axios.defaults.headers.common['uid'] = this.props.uid;

    axios.interceptors.response.use(function (response) {
      // Do something with response data
      return response;
    }, function (error) {
      console.log(error);
      if (error !== undefined && error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        context.props.invalidateLogin();
        console.log('Error', error.response.data);
        if (error.response.data.hasOwnProperty('errors')) {
          context.props.createErrorAlert(error.response.data.errors.map((e) => e));
        }
      }

      if (error && error.response && error.response.status === 400) {
        if (error.response.data.hasOwnProperty('error')) {
          context.props.createErrorAlert(error.response.data.error);
        }

        if (error.response.data.hasOwnProperty('errors')) {
          const errorMessage = error.response.data.errors.map(function (error) {
            return error + " ";
          });

          context.props.createErrorAlert(errorMessage);
        }
      }
      return Promise.reject(error);
    });
  }

  validateToken = () => {
    this.props.validateToken(this.props.access_token, this.props.client_token, this.props.uid);
  }

  render() {

    this.initializeAPIHeaders();

    return (
      <BrowserRouter basename="/">
        <div>
          <SiteLayout>
            <Switch>
              <this.AuthenticatedRoute exact path='/' component={DashboardContainer} />
              <this.AuthenticatedRoute exact path='/dashboard' component={DashboardContainer} />
              <this.AuthenticatedRoute exact path='/people' component={PeopleContainer} />
              <this.AuthenticatedRoute exact path='/people/manage' component={ManagePeopleSmartContainer} />
              <this.AuthenticatedRoute exact path='/people/:id' component={PersonContainer} />
              <this.AuthenticatedRoute exact path='/people/:id/update' component={UpdatePerson} />
              <this.AuthenticatedRoute exact path='/classes' component={ChurchClassesContainer} />
              <this.AuthenticatedRoute exact path='/classes/:id' component={ChurchClassContainer} />
              <this.AuthenticatedRoute exact path='/classes/:id/update' component={UpdateChurchClassContainer} />
              <this.AuthenticatedRoute exact path='/attendance' component={AttendanceSmartComponent} />
              <this.AuthenticatedRoute exact path='/attendance/:id' component={ChurchClassAttendanceSmartComponent} />
              <this.AuthenticatedRoute exact path='/checkups' component={CheckupsSmartComponent} />
              <Route exact path='/forgot_password' component={ForgotPasswordEmailForm} />
              <Route exact path='/registerStudent' component={RegisterStudentForm} />
              <Route exact path='/login' component={LoginContainer} />
              <Route exact path='/register' component={RegisterUserSmartComponent} />
              <Route exact path='/reset_password/:token' component={ResetPassword} />

              <AdminOnly>
                <this.AuthenticatedRoute exact path='/users/pending' component={PendingUsers} />
              </AdminOnly>


            </Switch>
          </SiteLayout>
        </div>
      </BrowserRouter>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.logged_in,
    access_token: state.auth.access_token,
    client_token: state.auth.client_token,
    uid: state.auth.uid
  };
};

export default connect(MapStateToProps, { validateToken, invalidateLogin, createErrorAlert, createInfoAlert })(Routes);