import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const FETCH_PEOPLE = 'FETCH_PEOPLE';

export function fetchPeople() {
  return {
    type: FETCH_PEOPLE,
    payload: axios.get(`${API_URL}/people`)
  };
}

export function addPerson(params) {
  return axios.post(`${API_URL}/people`, params);
}

export function sendCSVPeopleImport(file) {
  return axios.post(`${API_URL}/people/import`, {file: file});
};

export function deletePerson(id) {
  return axios.delete(`${API_URL}/people/${id}`);
}

export function getAllServants() {
  return axios.get(`${API_URL}/people/servants`);
};

export function getAllStudents() {
  return axios.get(`${API_URL}/people/students`);
}

export function registerStudents(data) {
  return axios.post(`${API_URL}/people/register`, {data: data});
}

export function uploadProfilePic(file, person_id) {
  let formData = new FormData();

  formData.append('photo', file);
  formData.append('person_id', person_id);

  return axios.post(`${API_URL}/people/profile_pic`, formData);
}