import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../pageHeader';
import SelectablePeopleGrid from '../selectablePeopleGrid';
import { Row, Col, Button } from 'reactstrap';
import { getStudentsOfClass, getClass } from '../../../actions/church_classes/churchClassActions';
import { sendBatchAttendanceForDateTime, getClassAttendancesInOrderedDates } from '../../../actions/attendance/attendanceActions';
import AttendanceList from '../attendanceList';
import { connect } from 'react-redux';
import { createInfoAlert } from '../../../actions/alerts/alertActions';
import moment from 'moment-timezone';
import AttendanceDate from '../attendanceDate';

class ChurchClassAttendanceSmartComponent extends Component {

    /* this.props.match.params.id has class id */

    static propTypes = {
        timezone: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            attendance: [], // structure: {present_students: [1, 2, 3...], absent_students: [1, 2, 3...], excused_students: [1, 2, 3...]}
            previousDateOrderedAttendances: {},
            class_info: {},
            resetAttendanceSelections: false,
            date: ''
        };
    }

    getAttendanceFromChild = (attendance) => {
        // attendance structure: {present_students: [1, 2, 3...], absent_students: [1, 2, 3...], excused_students: [1, 2, 3...]}
        console.log('Attendance from child: ', attendance);
        this.setState({ attendance: attendance });
    }

    fetchPreviousAttendances = () => {
        const class_id = this.props.match.params.id;
        const _this = this;

        getClassAttendancesInOrderedDates(class_id).then(function (response) {
            _this.setState({
                previousDateOrderedAttendances: response.data.attendances_by_date
            });
        });
    }

    takeAttendance = () => {
        const class_id = this.props.match.params.id;
        const _this = this;

        sendBatchAttendanceForDateTime(this.state.attendance, class_id, this.state.date)
            .then(function (response) {
                _this.fetchPreviousAttendances();
                _this.resetAttendanceSelections();
                _this.props.createInfoAlert('Attendance has been recorded successfully. God bless your service.');
            })
            .catch(function (error) {
                _this.resetAttendanceSelections();
            });
    }

    resetAttendanceSelections = () => {
        this.setState({
            resetAttendanceSelections: !this.state.resetAttendanceSelections,
            attendance: []
        });

    }

    fetchClassInfo = () => {
        const _this = this;
        const class_id = this.props.match.params.id;

        getClass(class_id).then(function (response) {
            _this.setState({ class_info: response.data });
        })
    }

    componentDidMount() {
        this.fetchPreviousAttendances();
        this.fetchClassInfo();
    }

    render() {
        const class_id = this.props.match.params.id;
        const _this = this;
        const { class_info } = this.state;
        const { timezone } = this.props;

        return (
            <div>
                <PageHeader>
                    <h1>Take Attendance - {class_info.name}</h1>
                </PageHeader>
                <Row>
                    <p>Select students below who are present and click submit.</p>
                    <Col md='9'>
                        <SelectablePeopleGrid
                            key={this.state.resetAttendanceSelections}
                            getPeople={() => getStudentsOfClass(class_id)}
                            returnAttendanceToParent={this.getAttendanceFromChild}
                            resetSelectedPeople={this.resetAttendanceSelections} />

                        <br />
                        <br />
                    </Col>
                    <Col md='3'>
                        <h6>Attendance Date</h6>
                        <AttendanceDate style={{ marginBottom: '5px' }} timezone={timezone} onDateChange={(date) => _this.setState({ date: date.format('YYYY-MM-DD HH:mm:ss') })} />
                        <Button onClick={this.takeAttendance} color='primary'>
                            Submit Attendance</Button>

                        <br /> <br />

                    </Col>
                </Row>

                <Row>
                    <Col md='10'>
                        <AttendanceList
                            attendances={this.state.previousDateOrderedAttendances}
                            timezone={this.props.timezone} />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToDispatch = (state) => {
    return {
        timezone: state.auth.timezone
    }
}

export default connect(mapStateToDispatch, { createInfoAlert })(ChurchClassAttendanceSmartComponent);