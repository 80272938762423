import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const FETCH_PERSON = 'FETCH_PERSON';

export function fetchPerson(person_id) {
    return axios.get(`${API_URL}/people/${person_id}`);
}

export function updatePerson(params) {
    const id = params.id;
    return axios.put(`${API_URL}/people/${id}`, params);
}