import React, {useEffect, useState} from 'react';
import {getServantStats} from '../../../actions/dashboard/statisticActions';
import {useSelector} from 'react-redux';
import {
    Typography,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';

export default function UserActivityList() {
    const [servantStats, setServantStats] = useState({});
    const authState = useSelector(state => state.auth);
    useEffect(() => {
        let mount = true;
        
        getServantStats(authState.user_id).then(response => {
            if(mount) {
                setServantStats(response.data)
            }
        })

        return () => { mount = false }
    }, [setServantStats]);

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th">Number of Classes:</TableCell>
                            <TableCell component="th">{servantStats.num_classes}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th">Number of Checkups:</TableCell>
                            <TableCell component="th">{servantStats.num_checkups}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th">Number of Students:</TableCell>
                            <TableCell component="th">{servantStats.num_students}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}