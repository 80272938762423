import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import EditIcon from '@material-ui/icons/Edit';
import { DateTimePicker } from '@material-ui/pickers';

function AttendanceDate(props) {
    const { timezone, onDateChange } = props;
    const [date, setDate] = useState(null);
    const [editDateToggle, setEditDateToggle] = useState(false);

    useEffect(() => {
        let mount = true;
        if (mount === true) {
            setDate(moment().utc());
        }
        return () => mount = false;
    }, [])

    useEffect(() => {
        if (date != null || date != undefined) {
            onDateChange(date.utc());
        }
    }, [date]);

    return (
        <div style={{ display: 'flex' }}>
            <DateTimePicker
                hidden
                inputProps={{ style: { cursor: 'pointer', border: 'solid #1d6cb0 2px', width: '175px', padding: '7px', fontSize: '.8em' } }}
                inputVariant='filled'
                value={date}
                format="dddd, MM/DD/YYYY hh:mm a"
                onChange={momentObj => { setDate(momentObj); }}
                open={editDateToggle}
                onAccept={() => setEditDateToggle(false)}
                onClose={() => setEditDateToggle(false)}
                disableFuture
            />
            <p style={{
                fontSize: '.8em',
                paddingTop: '3px',
                backgroundColor: 'rgba(142, 236, 110, .5)',
                padding: '5px'
            }} readOnly>
                {date != null ? date.tz(timezone).format('dddd, MM/DD/YYYY hh:mma') : ''}
            </p>
            <EditIcon style={{
                cursor: 'pointer',
                height: '29px',
                backgroundColor: '#3994F6',
                border: 'solid #1470CF 1px'
            }}
                onClick={() => setEditDateToggle(true)}
            />
        </div>
    );
}

export default AttendanceDate;