import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import unknown_person_logo from '../../images/coptic_cross.png';

export default class PersonCard extends Component {

  static propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    photoURL: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isToggled: false
    };
  }

  renderCard = () => {
    const fullName = this.props.firstName + " " + this.props.lastName;
    const photoURL = this.props.photoURL ? this.props.photoURL : unknown_person_logo;
    const style = {
      cardStyle: {
        padding: 0,
        textAlign: 'center',
        whiteSpace: 'nowrap'
      }
    };

    return (
      <Card style={style.cardStyle} variant='outlined'>
        <CardContent style={{ padding: '0' }}>
          <img style={{ width: '200px', maxHeight: '200px' }} src={photoURL} />
        </CardContent>
        <CardContent>
          <Typography variant="p" style={{ whiteSpace: 'normal' }}>{fullName}</Typography>
        </CardContent>
      </Card>
    )
  }

  render() {
    return (
      this.renderCard()
    )
  }
}