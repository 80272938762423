import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import unknown_person_logo from '../../images/coptic_cross.png';
import moment from 'moment-timezone';

export default class PeopleTable extends Component {
    static propTypes = {
        people: PropTypes.array
    };

    renderPeopleTable = () => {
        const age = (birthdate) => moment().diff(birthdate, 'years');

        const styles = {
            profile_pic: {
                height: '100px',
                width: '100px',
                borderRadius: '50px'
            }
        }

        return (
            <div>
                <TableContainer component={Paper}>
                    <Table stickyHeader style={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Picture</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Middle Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Age</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>View Profile</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.people.map((person, id) => {
                                const photo_url = (person.photo_url && person.photo_url.length > 0) ? person.photo_url : unknown_person_logo;
                                return (
                                    <TableRow hover key={id}>
                                        <TableCell style={{ maxWidth: '100px', wordWrap: 'break-word' }}><img style={styles.profile_pic} src={photo_url} /></TableCell>
                                        <TableCell style={{ maxWidth: '100px', wordWrap: 'break-word' }}>{person.first_name}</TableCell>
                                        <TableCell style={{ maxWidth: '20px', wordWrap: 'break-word' }}>{person.middle_name}</TableCell>
                                        <TableCell style={{ maxWidth: '100px', wordWrap: 'break-word' }}>{person.last_name}</TableCell>
                                        <TableCell style={{ maxWidth: '20px', wordWrap: 'break-word' }}>{age(person.birthdate)}</TableCell>
                                        <TableCell style={{ maxWidth: '170px', wordWrap: 'break-word' }}>{person.email}</TableCell>
                                        <TableCell style={{ maxWidth: '70px', wordWrap: 'break-word' }}>{person.primary_phone}</TableCell>
                                        <TableCell><Link to={`people/${person.id}`}>View Profile</Link></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <br />
            </div>
        )
    }
    render() {
        return this.renderPeopleTable();
    }
}