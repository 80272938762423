import React, { Component } from "react";
import { Navbar, Row, Col, Container, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


class Header extends Component {

  renderLoginLink = () => {
    return (
      <Link to='/login'>Login</Link>
    )
  }

  renderUserWelcome = () => {
    return (
        <NavLink >Welcome { this.props.first_name }</NavLink>
    )
  }

	render() {
		return (
      <div>

       	<Navbar  toggleable>
            <NavbarToggler right onClick={this.toggle} />
            <NavbarBrand href="/">Coptic Shepherd</NavbarBrand>
              <Container>
                <Nav navbar>
                    <NavItem>
                      <NavLink href="/people">People</NavLink>
                    </NavItem>
                    <NavItem>
                      { this.props.logged_in ? this.renderUserWelcome() : null }
                    </NavItem>
                </Nav>
              </Container>        
        </Navbar>
      </div>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    logged_in: state.auth.logged_in,
    first_name: state.auth.first_name
  };
}

export default connect(mapStateToProps)(Header);