import React, { useEffect, useState } from 'react';
import PieGraph from '../pieGraph';
import { MenuItem, List, Link, Typography } from '@material-ui/core';
import {getAttendancePieChartData} from '../../../actions/dashboard/statisticActions';

function processPieChartData(data) {
    /*
        Structure of data:
        [
            {
                class_name: High School,
                presences: 20,
                excused: 200,
                absences: 21
            }
        ]
    */

    return data.map((class_data, index) => {
        return {
            id: index,
            class_name: class_data.class_name,
            data: [class_data.presences, class_data.excused, class_data.absences]
        }
    });
}

export default function AttendancePieChart() {
    let [pieChartArray, setPieChartArray] = useState([]);
    let [pieChartIndex, setPieChartIndex] = useState(0);

    useEffect(() => {
        let mounted = true;
        getAttendancePieChartData().then(response => {
            if(mounted) {
                setPieChartArray(processPieChartData(response.data));
            }
        });

        return () => { mounted = false; }
    }, [setPieChartArray]);
    console.log("PIE CHART DATA::: ", pieChartArray);
    return (
        <React.Fragment>
            { pieChartArray.length > 0 && (
                <React.Fragment>
                    <List>                
                        {
                            pieChartArray.map((class_item, index) => {
                                return (
                                    <MenuItem 
                                        selected={pieChartArray[pieChartIndex].id === index}
                                        style={{"display": "inline-flex"}}
                                    >
                                        <Link href='' onClick={e => {e.preventDefault(); setPieChartIndex(index)}}>
                                            {class_item.class_name}
                                        </Link>
                                    </MenuItem>
                                )
                            })    

                        }
                    </List><br />
                    { pieChartArray[pieChartIndex].data.length > 0 ?
                    <PieGraph
                        key={pieChartIndex}
                        labels={['Presences', 'Excused', 'Absences']}
                        data={pieChartArray[pieChartIndex].data}
                    />
                    : <Typography variant='h6'>Not Enough Data to Chart Yet</Typography>
                    }
                </React.Fragment>
            )
            }
        </React.Fragment>
    );
}