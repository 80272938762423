import React, { Component } from 'react';
import { Table, Row, Col, Button } from 'reactstrap';
import PageHeader from '../../pageHeader';
import PendingUserApprovalTable from '../pendingUserApprovalTable';
import { fetchPendingUsers, approveUser, denyUser } from '../../../actions/users/userActions';


export default class PendingUsers extends Component {
    
    render() {
        return (
            <div>
                <PageHeader>
                    <h1>Users Pending Approval</h1>
                </PageHeader>

                <PendingUserApprovalTable
                    fetchPendingUsers={fetchPendingUsers}
                    approveUser={approveUser}
                    denyUser={denyUser} />
            </div>
        )
    }
}