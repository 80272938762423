import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Input, Form, FormGroup, Label} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import StatesCombobox from '../common/form_helpers/statesCombobox';

export default class UpdatePersonForm extends Component {
    static propTypes = {
        person: PropTypes.object,
        updatePerson: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            personUpdated: false,
            person: {}
        };
    }

    handleChange = (e) => {
        this.setState({
            person: {...this.state.person, [e.target.name]: e.target.value}
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const _this = this;
        this.props.updatePerson(this.state.person)
            .then(function(response) {
                _this.setState({ personUpdated: true });
            })
    }

    componentDidMount() {
        this.setState({ person: this.props.person });
    }
    componentWillReceiveProps(newProps) {
        if(this.props.person !== newProps.person) {
            this.setState({ person: newProps.person });
        }
    }

    renderForm = () => {
        const person = this.state.person;
        const birthdate = person.birthdate ? person.birthdate.substring(0, 10) : '';
        return (
            <Form onSubmit={this.handleSubmit}>
                <FormGroup row>
                    <Label md={2}>First Name*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='first_name' type='text'
                        value={person.first_name} required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Middle Name</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='middle_name'
                        value={person.middle_name} type='text' /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Last Name*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='last_name' 
                        value={person.last_name} type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Primary Phone*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='primary_phone' 
                        value={person.primary_phone} type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Secondary Phone</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='secondary_phone' 
                        value={person.secondary_phone} type='text' /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Birthdate*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='birthdate' 
                        value={birthdate} type='date' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Email*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='email' 
                        value={person.email} type='email' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>City*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='city' 
                        value={person.city} type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Street*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='street' 
                        value={person.street} type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Zip Code*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='zip' 
                        value={person.zip} type='text' required /> </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>State</Label>
                    <Col md={10}> 
                        <StatesCombobox 
                            handleStateChoice={this.handleChange} 
                            currentStateSelection={person.state} /> 
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label md={2}>Country*</Label>
                    <Col md={10}> <Input onChange={this.handleChange} name='country' 
                        value={person.country} type='text' required /> </Col>
                </FormGroup>
                <Row>
                    <Col md='10' />
                    <Col md='2'>
                        <Button color='primary'>Submit</Button>
                    </Col>
                </Row>
            </Form>
        )
    }

    render() {
        const person = this.state.person;
        return (
            <div>
                { this.state.personUpdated && <Redirect to={`/people/${person.id}`} /> }
                { this.state.person !== {} && this.renderForm() }
           </div>
        )
    }
}