import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import CollapsableSection from '../common/collapsableSection';
import moment from 'moment-timezone';

export default class AttendanceList extends Component {

    static propTypes = {
        attendances: PropTypes.object,
        timezone: PropTypes.string
    };

    constructor(props) {
        super(props);
    }

    renderAttendanceInformation = () => {

    }
    renderPreviousAttendanceInfo = (date) => {
        const { attendances } = this.props;

        return (
            <Row>
                <Col md='4'>
                    <h4>Present</h4>
                    {
                        attendances[date].map(function (attendance) {
                            if (attendance.attendance_status.name === 'Present') {
                                return <Link key={attendance.student.id} to={`/people/${attendance.student.id}`}><p key={attendance.id}>{`${attendance.student.first_name} ${attendance.student.last_name}`}</p></Link>;
                            }
                        })
                    }
                </Col>

                <Col md='4'>
                    <h4>Absent</h4>
                    {
                        attendances[date].map(function (absence) {
                            if (absence.attendance_status.name === 'Absent') {
                                return <Link key={absence.id} to={`/people/${absence.student.id}`}><p key={absence.id}>{`${absence.student.first_name} ${absence.student.last_name}`}</p></Link>;
                            }
                        })
                    }
                </Col>

                <Col md='4'>
                    <h4>Excused</h4>
                    {
                        attendances[date].map(function (excused) {
                            if (excused.attendance_status.name === 'Excused') {
                                return <Link key={excused.id} to={`/people/${excused.student.id}`}><p key={excused.id}>{`${excused.student.first_name} ${excused.student.last_name}`}</p></Link>;
                            }
                        })
                    }
                </Col>
            </Row>
        );
    }

    render() {
        const attendances = this.props.attendances;
        const _this = this;
        console.log('Timezone', this.props.timezone);
        return (
            <div>
                <h4>Previous Attendances</h4>
                {
                    Object.keys(attendances).map(function (date, index) {
                        const formattedDate = moment.utc(date, 'YYYY-MM-DDHH:mm:ss.SSS').tz(_this.props.timezone).format('dddd, MMMM D, YYYY - h:mm:ssa');

                        return (
                            <CollapsableSection
                                key={index}
                                title={formattedDate}
                            >
                                {_this.renderPreviousAttendanceInfo(date)}
                            </CollapsableSection>
                        )
                    })
                }
            </div>
        );
    }
}