import React, { Component } from 'react';
import UserSidebarProfilePic from './userSidebarProfilePic';
import PropTypes from 'prop-types';


export default class SidebarUser extends Component {

    static propTypes = {
        first_name: PropTypes.string,
        profile_pic_link: PropTypes.string,
    };


    render() {
        return (
            <div>
                <UserSidebarProfilePic link={this.props.profile_pic_link} />
                <br />
                <br />
                <h4>Welcome {this.props.first_name}</h4>
                <hr />
            </div>
        );
    }
}